import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { useConsumerCart } from './useConsumerCart'
import {
  CartMutationResponse,
  CartProductError,
  DiscountError,
  DiscountWarning,
  InvalidTimeslotWarning
} from 'shop/types/cart'
import { useAppContent, useModal } from './useGlobalContext'
import { trackAddShippingInfoConsumerCart } from 'shop/components/Checkout/tracking/helpers'
import {
  findErrorWarning,
  isCartProductError,
  getCurrentDiscountWarningErrors,
  isTimeSlotError
} from 'shop/components'

interface CheckoutState {
  giftWrapped: boolean
  setGiftWrapped: (value: boolean) => void
  giftWrapMessage: string | null
  setGiftWrapMessage: (message: string | null) => void
  trackAddShippingInfo: () => void
  updateCartGiftWrapping: (message: string | null) => void
  timeSlotValidation: InvalidTimeslotWarning | null
  productError: CartProductError | null
  discountErrorWarnings: (DiscountWarning | DiscountError)[]
  rewardsRef: React.RefObject<HTMLDivElement>
  isPaying: boolean
  setIsPaying: React.Dispatch<React.SetStateAction<boolean>>
  isTippingOpen: boolean
  setIsTippingOpen: React.Dispatch<React.SetStateAction<boolean>>
  openTippingModal: () => void
}

const CheckoutContext = createContext<CheckoutState>({
  giftWrapped: false,
  setGiftWrapped: () => null,
  giftWrapMessage: null,
  setGiftWrapMessage: () => null,
  trackAddShippingInfo: () => null,
  updateCartGiftWrapping: () => null,
  timeSlotValidation: null,
  productError: null,
  discountErrorWarnings: [],
  rewardsRef: { current: null },
  isPaying: false,
  setIsPaying: () => false,
  isTippingOpen: false,
  setIsTippingOpen: () => false,
  openTippingModal: () => null
})

export const CheckoutContextProvider: React.FC = ({ children }) => {
  const {
    cart: consumerCart,
    updateConsumerCart,
    setCart,
    warnings: consumerCartWarnings,
    errors: consumerCartErrors
  } = useConsumerCart()
  const { merchantName } = useAppContent()
  const { openModal } = useModal()
  const storeName = consumerCart?.store?.name

  const [giftWrapped, setGiftWrapped] = useState<boolean>(false)
  const [giftWrapMessage, setGiftWrapMessage] = useState<string | null>(null)
  const [timeSlotValidation, setTimeSlotValidation] =
    useState<InvalidTimeslotWarning | null>(null)
  const [discountErrorWarnings, setDiscountErrorWarnings] = useState<
    (DiscountWarning | DiscountError)[]
  >([])
  const [productError, setProductError] = useState<CartProductError | null>(
    null
  )
  const [isPaying, setIsPaying] = useState<boolean>(false)
  const [isTippingOpen, setIsTippingOpen] = useState<boolean>(false)

  // Ref used for intersection observer/scroll to rewards section on mobile when rewards are available
  const rewardsRef = useRef<HTMLDivElement>(null)

  const getGiftWrapState = () => {
    const { additionalItems } = consumerCart || {}
    const { giftWrapping } = additionalItems || {}
    const message = giftWrapping?.message || null
    return {
      giftWrapped: !!message,
      giftWrapMessage: message
    }
  }

  /** Gets & sets gift wrap state values on consumer cart load */
  useEffect(() => {
    const { giftWrapped, giftWrapMessage } = getGiftWrapState()
    setGiftWrapped(giftWrapped)
    setGiftWrapMessage(giftWrapMessage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerCart?.id])

  /** Updates consumer cart on gift wrapping change & sets cart to show cost changes */
  const updateCartGiftWrapping = (message: string | null) => {
    // If gift wrapping is disabled, remove the message from the cart
    return updateConsumerCart({
      giftWrapMessage: message ? message : null
    }).then((cartResponse: CartMutationResponse | null) => {
      const { cart } = cartResponse || {}
      if (cart) {
        setCart(cart)
      }
    })
  }

  /** Handles the opening of the Tipping Modal */
  const openTippingModal = () => {
    if (isPaying) return
    setIsTippingOpen(true)
  }

  const trackAddShippingInfo = useCallback(() => {
    if (consumerCart && merchantName && storeName) {
      trackAddShippingInfoConsumerCart(consumerCart, merchantName, storeName)
    }
  }, [consumerCart, merchantName, storeName])

  /** Handles consumer cart timeslot warning */
  useEffect(() => {
    if (!consumerCartWarnings.length) {
      if (!!timeSlotValidation) setTimeSlotValidation(null)
      return
    }

    const timeslotWarning = findErrorWarning(
      consumerCartWarnings,
      isTimeSlotError
    ) as InvalidTimeslotWarning

    if (timeslotWarning) {
      setTimeSlotValidation(timeslotWarning)
      openModal('timeslotV2')
    } else setTimeSlotValidation(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerCartWarnings])

  /** Handles cart product errors */
  useEffect(() => {
    if (!consumerCartErrors.length) {
      setProductError(null)
      return
    }

    const currentProductError = findErrorWarning(
      consumerCartErrors,
      isCartProductError
    ) as CartProductError

    if (currentProductError) {
      setProductError(currentProductError)
    } else setProductError(null)
  }, [consumerCartErrors])

  /** Handles consumer cart discount warnings/errors */
  useEffect(() => {
    if (!consumerCartWarnings.length && !consumerCartErrors.length) {
      // reset all validations if there are no consumerCartWarnings
      if (!!discountErrorWarnings.length) setDiscountErrorWarnings([])
      return
    }

    const currentDiscountErrorWarnings = getCurrentDiscountWarningErrors(
      consumerCartErrors,
      consumerCartWarnings
    )

    if (currentDiscountErrorWarnings.length) {
      setDiscountErrorWarnings(currentDiscountErrorWarnings)
    } else setDiscountErrorWarnings([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerCartErrors, consumerCartWarnings])

  return (
    <CheckoutContext.Provider
      value={{
        giftWrapped,
        setGiftWrapped,
        giftWrapMessage,
        setGiftWrapMessage,
        trackAddShippingInfo,
        updateCartGiftWrapping,
        timeSlotValidation,
        productError,
        discountErrorWarnings,
        rewardsRef,
        isPaying,
        setIsPaying,
        isTippingOpen,
        setIsTippingOpen,
        openTippingModal
      }}
    >
      {children}
    </CheckoutContext.Provider>
  )
}

export const useCheckout = () => useContext(CheckoutContext)
