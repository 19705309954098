import { useState } from 'react'
import gql from 'graphql-tag'
import { ApolloClient } from '@apollo/client'

declare global {
  interface Window {
    grecaptcha: ReCaptchaInstance
  }
}

interface ReCaptchaInstance {
  enterprise: {
    ready: (cb: () => any) => any
    execute: (
      sitekey: string,
      options: ReCaptchaExecuteOptions
    ) => Promise<string>
    render: (id: string, options: ReCaptchaRenderOptions) => any
  }
}

interface ReCaptchaExecuteOptions {
  action: string
}

interface ReCaptchaRenderOptions {
  sitekey: string
  size: 'invisible'
}

const sitekey = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY

export function useRecaptcha() {
  const [recaptcha, setRecaptcha] = useState<ReCaptchaInstance>()

  if (!sitekey) return

  const loadRecaptchaScript = () => {
    if (!window.grecaptcha) {
      const head = document.getElementsByTagName('head')[0]
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.defer = true
      script.onload = function () {
        window.grecaptcha.enterprise.ready(() => {
          setRecaptcha(window.grecaptcha)
        })
      }
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${sitekey}`
      head.appendChild(script)
      return
    }
    setRecaptcha(window.grecaptcha)
  }

  const executeRecaptcha = (action: string) => {
    return new Promise<string>((resolve, reject) => {
      if (recaptcha) {
        resolve(recaptcha.enterprise.execute(sitekey, { action }))
      } else {
        reject(new Error('Recaptcha script not available'))
      }
    })
  }

  const validateRecaptchaToken = (
    client: ApolloClient<object>,
    cartId: string,
    token: string,
    saveCardDetails?: boolean
  ) =>
    client
      .mutate({
        mutation: VALIDATE_RECAPTCHA_TOKEN,
        variables: {
          cartId,
          recaptchaToken: token,
          savePaymentMethod: !!saveCardDetails,
          lock: true // lock cart
        }
      })
      .then(({ data }) => {
        return data.payForCart.nextStep as string
      })

  return {
    executeRecaptcha,
    loadRecaptchaScript,
    validateRecaptchaToken
  }
}

/**
 * MUTATION to send token to server to calculat score
 */

const VALIDATE_RECAPTCHA_TOKEN = gql`
  mutation payForCart(
    $cartId: ID!
    $recaptchaToken: String
    $savePaymentMethod: Boolean
    $lock: Boolean
  ) {
    payForCart(
      cartId: $cartId
      recaptchaToken: $recaptchaToken
      savePaymentMethod: $savePaymentMethod
      lock: $lock
    ) {
      clientSecret
      nextStep
    }
  }
`
