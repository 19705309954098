import { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { useShop, usePrevious } from 'shop/hooks'
import { QUERY_GET_STORE_CATEGORIES, GetCategoriesVariables } from 'shop/client'
import { ProductList } from 'shop/components'
import { Categories } from './Categories'
import { isTableOrder } from 'shop/components/Cart/utils'
import { includesCurrentCategory } from 'shop/components/Shop/Categories'

import {
  trackFBPixeltrackProductListViewed,
  trackGA4ProductListViewed
} from 'tracker'
import { CategoryWithProducts } from 'shop/components/Shop/Categories/types'
import { filterCatProductsBySearchTerm } from 'shop/components/Product/utils'
import { FulfillmentType } from 'shop/types'
import { StickyCard } from './StickyCard'
import { useShopPage } from 'shop/hooks'

interface ShopBodyProps {
  slug: string
  merchant: string
}

export const ShopBody = ({ slug, merchant: merchantSlug }: ShopBodyProps) => {
  const mounted = useRef(true)
  const {
    merchant,
    useShopClient,
    cartSession,
    setIsStoreLoading,
    setIsProductsLoading
  } = useShop()

  const { handleAutoScrolling, isSearching, searchTerm } = useShopPage()

  const client = useShopClient()
  const { cart, isCartLoading, setCart, cartId } = cartSession
  const [categories, setCategories] = useState<CategoryWithProducts[]>([])
  const [availableCategories, setAvailableCategories] = useState<
    CategoryWithProducts[]
  >([])
  const [lastSelectedCategory, setLastSelectedCategory] = useState<string>('')
  const [selectedCategory, setSelectedCategory] = useState<string>('')

  const [shouldReloadProducts, setShouldReloadProducts] = useState(false)

  const oldSlug = usePrevious<string>(slug)
  const oldDeliverBy = usePrevious(cart?.deliver_by)
  const oldFulfillmentType = usePrevious(cart?.fulfillment_type)
  const oldFulfillmentTimeRange = usePrevious(cart?.fulfillment_time_range)
  const oldStore = usePrevious(cart?.store_id)

  const storeSlug = useMemo(() => {
    return cart?.store.slug || slug
  }, [slug, cart?.store.slug])

  useEffect(() => {
    if (!mounted.current) return
    if (cartId === null) setCart(null)
  }, [cartId, setCart])

  /** Changing store slug should reload products, return if cart (cart changes handled by useEffect below) */
  useEffect(() => {
    if (cartId || oldSlug === storeSlug) return
    setShouldReloadProducts(true)
  }, [storeSlug, cartId])

  /** changing cart date/time/type/store triggers a reload in the shops products */
  useEffect(() => {
    if (
      cart &&
      (oldDeliverBy !== cart.deliver_by ||
        oldFulfillmentType !== cart.fulfillment_type ||
        oldFulfillmentTimeRange !== cart.fulfillment_time_range ||
        oldStore !== cart.store_id)
    ) {
      setShouldReloadProducts(true)
    }
  }, [
    cart?.deliver_by,
    cart?.fulfillment_type,
    cart?.fulfillment_time_range,
    cart?.store_id
  ])

  useEffect(() => {
    // Load the categories with products data
    // cartIds are intialised when coming from the Landing page
    // and hence should not attempt to load any categories until the cart has intialised
    // if we already have category data but are updating the cart, exit.
    // otherwise force a reload if we want to through shouldReloadProducts.
    if (
      (isCartLoading || (cartId && !cart) || categories.length) &&
      !shouldReloadProducts
    )
      return

    let categoryVariables: GetCategoriesVariables

    // no cart and no cartId means deeplinking
    if (!cart) {
      categoryVariables = {
        storeSlug: slug
      }
    } else {
      const isDineIn = isTableOrder(cart.metadata)
      const fulfillmentType: typeof cart.fulfillment_type = isDineIn
        ? 'order_at_table'
        : cart.fulfillment_type

      categoryVariables = {
        fulfillmentDatetime: cart
          ? cart.deliver_by
            ? `${cart.deliver_by}Z`
            : null
          : undefined,
        fulfillmentType: fulfillmentType.toUpperCase() as FulfillmentType
      }

      if (cart.store_id) {
        categoryVariables = {
          ...categoryVariables,
          storeId: cart.store_id
        }
      } else {
        categoryVariables = {
          ...categoryVariables,
          storeSlug: slug
        }
      }
    }
    setIsProductsLoading(true)
    client
      .query({
        query: QUERY_GET_STORE_CATEGORIES,
        variables: categoryVariables,
        fetchPolicy: 'network-only'
      })
      .then((result) => {
        setCategories(result.data.getCategories)
        setIsProductsLoading(false)
        setShouldReloadProducts(false)
        setLastSelectedCategory('')
      })
  }, [cart, isCartLoading, slug, shouldReloadProducts]) // eslint-disable-line

  const track = useCallback(() => {
    if (categories.length > 0 && selectedCategory) {
      // legacy tracking
      trackFBPixeltrackProductListViewed()

      trackGA4ProductListViewed('slerpGA4Tracking')
      trackGA4ProductListViewed('merchantGA4Tracking')
    }
  }, [categories, selectedCategory])

  /** ProductList onLoad */
  useEffect(() => {
    setIsStoreLoading(true)
    track()

    window.scrollTo(0, 0)
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  /** Once the Merchant has loaded we can assume the useShop hook has loaded.
   * This is to keep functionality of a getMerchant call that has now been removed.
   */
  useEffect(() => {
    if (merchant) {
      setIsStoreLoading(false)
    }
  }, [merchant])

  /** Sets the initial category selected */
  useEffect(() => {
    if (!mounted.current) return
    if (!availableCategories.length) return

    if (
      isSearching &&
      availableCategories.length > 0 &&
      !includesCurrentCategory(availableCategories, lastSelectedCategory) &&
      lastSelectedCategory !== ''
    ) {
      return setSelectedCategory(availableCategories[0].id)
    }
    if (lastSelectedCategory) return setSelectedCategory(lastSelectedCategory)
    // set the default cateogry to the first in the list that has an id
    const firstCatIndex = availableCategories.findIndex((cat) => cat.id)
    return setSelectedCategory(availableCategories[firstCatIndex].id)
  }, [lastSelectedCategory, availableCategories, isSearching])

  /** Filter Categories & Products further when searching */
  useEffect(() => {
    if (searchTerm && isSearching && categories.length) {
      const categoriesWithFiteredProducts = categories.map((cat) => {
        return {
          ...cat,
          products: filterCatProductsBySearchTerm(
            cat.name,
            cat.products,
            searchTerm
          )
        }
      })

      const filteredCategories = categoriesWithFiteredProducts.filter((cat) => {
        return cat.products.length > 0
      })

      return setAvailableCategories(filteredCategories)
    }

    const filteredCategories = categories.filter((category) =>
      Boolean(category?.products?.length)
    )
    return setAvailableCategories(filteredCategories)
  }, [searchTerm, isSearching, categories])

  const handleSetCategory = (categoryId: string) => {
    setLastSelectedCategory(categoryId)
    setSelectedCategory(categoryId)
  }

  const handleCategoryChange = (categoryId: string) => {
    handleAutoScrolling()
    handleSetCategory(categoryId)

    const element = document.getElementById(`product-category-${categoryId}`)

    if (!element) {
      return
    }

    const yOffset = 150
    const scrollPositon =
      element.getBoundingClientRect().top + window.pageYOffset - yOffset

    window.scrollTo({ top: scrollPositon, behavior: 'smooth' })
  }

  return (
    <StickyCard
      id='shop-body-root'
      hasHeaderOffset
      header={
        <Categories
          selectedCategory={selectedCategory}
          onClick={handleCategoryChange}
          categories={availableCategories}
        />
      }
      content={
        <ProductList
          setActiveCategory={handleSetCategory}
          selectedCategory={selectedCategory}
          categories={availableCategories}
        />
      }
    />
  )
}
