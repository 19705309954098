import styled from '@emotion/styled'
import CartBreakdownItem from './CartBreakdownItem'
import { IoIosBicycle as Bicycle } from 'react-icons/io'
import { BsShop as Store } from 'react-icons/bs'
import { Price } from 'shop/types/cart'
import { LineBreak } from 'shop/components/Shop/commonStyles'
import TipBreakdown from './TipBreakdown'
import { isTipMoreThanZero } from 'shop/components/Tipping/utils'

interface Props {
  deliveryFee: Price | null
  isDelivery: boolean
  tip: string | null
  storeFee: Price | null | undefined
  isCartLoading: boolean
  isEditable?: boolean
}

const FeeBreakdown: React.FC<Props> = ({
  storeFee,
  isDelivery,
  deliveryFee,
  tip,
  isCartLoading,
  isEditable = false
}) => {
  const hasUserSelectedTipBefore = !!sessionStorage.getItem('hasSelectedTip')
  const hasTip = !!tip && isTipMoreThanZero(tip)

  // if a user has a tip already, or there is the ability to edit and they have made the selection before
  const showTipping = hasTip || !!(isEditable && hasUserSelectedTipBefore)

  const feeList = [
    !!storeFee?.base && (
      <CartBreakdownItem
        key='storeFee'
        LeftIcon={Store}
        label={'Restaurant Fee'}
        price={storeFee}
        isCartLoading={isCartLoading}
        withLineBreak={false}
        padding={'0px'}
      />
    ),
    isDelivery && (
      <CartBreakdownItem
        key='deliveryFee'
        LeftIcon={Bicycle}
        label={'Delivery Fee'}
        price={deliveryFee}
        pricePlaceholder={'To be calculated'}
        isCartLoading={isCartLoading}
        withLineBreak={false}
        padding={'0px'}
      />
    ),
    showTipping && (
      <TipBreakdown
        key='tip'
        tip={tip}
        isCartLoading={isCartLoading}
        isEditable={isEditable}
      />
    )
  ].filter((element) => !!element) as JSX.Element[]

  if (!feeList.length) return <></>
  return (
    <>
      <LineBreak height={'1px'} data-testid='cart-breakdown-line' />
      <FeeBreakdownContainer>
        {feeList.map((item) => item)}
      </FeeBreakdownContainer>
    </>
  )
}

const FeeBreakdownContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 0'
}))

export default FeeBreakdown
