import { useEffect, useContext } from 'react'
import styled from '@emotion/styled'
import FulfillmentOptionsNew from './FulfillmentOptionsNew'
import { useReactRouter, useModal } from 'shop/hooks'
import { LandingContextNew } from './LandingContext'
import { LandingProps, Store } from './types'
import {
  getStorePreOrderSettings,
  getUpcomingSpecialCutoffs
} from './preOrderUtils'
import { getEnabledPreorderDays } from './datePickerUtils'
import Spinner from '../Loader/Spinner'
import CloseButton from '../Modal/CloseButton'
import { isFulfillmentTypeEnabled } from './utils'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { SpinnerContainer } from './commonStyles'

interface LandingFormProps {
  setIsModalOpen: (value: boolean) => void
  onClose: () => void
}

const LandingFormNew = ({ setIsModalOpen, onClose }: LandingFormProps) => {
  const {
    merchant,
    stores,
    loadingStores,
    showFulfillmentOptions,
    setLoadingStores,
    fulfillmentType
  } = useContext(LandingContextNew)
  const isLoggedIn = localStorage.getItem('customerId') !== null
  const { openModal, closeModal } = useModal()
  const { history } = useReactRouter()

  const getOpenStores = (stores: Store[]) => {
    return stores?.filter((store) => store.is_open) || []
  }

  const openStores = getOpenStores(stores)

  const hasValidPreorderStore = !!stores.find((store) => {
    const preorderSettings = getStorePreOrderSettings(store)
    const acceptsPreorder =
      preorderSettings?.pickup_enabled || preorderSettings?.delivery_enabled
    const enabledCutoffs =
      preorderSettings && preorderSettings.cutoffs
        ? getEnabledPreorderDays(preorderSettings.cutoffs)
        : []
    const upcomingEnabledSpecialCutoffs = preorderSettings?.special_cutoffs_map
      ? getUpcomingSpecialCutoffs(preorderSettings?.special_cutoffs_map, true)
      : []
    const hasValidCutoffs =
      enabledCutoffs.length > 0 || upcomingEnabledSpecialCutoffs.length > 0

    return acceptsPreorder && hasValidCutoffs
  })

  const showForm = openStores.length > 0 || hasValidPreorderStore

  const handleLoginButtonClick = () => {
    if (isLoggedIn) {
      history.push('/account')
    } else {
      openModal('login')
    }
  }

  const deliveryEnabled = isFulfillmentTypeEnabled(stores, 'delivery')

  const showFormContent = !!(merchant && stores.length && showForm)

  const closeButton = (
    <CloseButton
      variant={'newFulfillmentModal'}
      handleCloseModal={() => {
        setIsModalOpen(false)
        setTimeout(() => closeModal('fulfillment'), 250)
        onClose()
      }}
    />
  )

  /** Set loadingStores to false if stores have loaded & none are open to showclosed stores message */
  useEffect(() => {
    if (!!stores.length && !getOpenStores(stores).length) {
      setLoadingStores({ ...loadingStores, [fulfillmentType]: false })
    }
  }, [stores])

  const form = (
    <>
      {showFormContent ? (
        <FormContent
          {...{
            stores,
            merchant,
            handleLoginButtonClick,
            onClose
          }}
        />
      ) : (
        <>
          {!stores.length || loadingStores[fulfillmentType] ? (
            <SpinnerContainer>
              <Spinner size='48px' />
            </SpinnerContainer>
          ) : (
            <>
              <ModalCloseButtonContainer allStoresClosed={true}>
                {closeButton}
              </ModalCloseButtonContainer>
              <ClosedStoresMessage>
                {merchant && merchant.closed_store_message}
              </ClosedStoresMessage>
            </>
          )}
        </>
      )}
    </>
  )

  const getLandingHeaderTitle = () => {
    if (showFulfillmentOptions) return 'How would you like to order?'
    if (deliveryEnabled) return 'Delivery details'
    else return 'Collection details'
  }

  const header = (
    <>
      {showFormContent && (
        <>
          <LandingHeader>
            <LandingTitle>
              {getLandingHeaderTitle()}
              <ModalCloseButtonContainer>
                {closeButton}
              </ModalCloseButtonContainer>
            </LandingTitle>
            <LandingSubtitle>
              This will help us determine availability
            </LandingSubtitle>
          </LandingHeader>
        </>
      )}
    </>
  )

  return (
    <>
      {header}
      <LandingContainer>{form}</LandingContainer>
    </>
  )
}

interface FormContentProps extends LandingProps {
  handleLoginButtonClick: () => void
  onClose: () => void
}

const FormContent = ({ merchant, stores, onClose }: FormContentProps) => {
  const { isModalOpen, closeModal } = useModal()

  useEffect(() => {
    isModalOpen('login') && closeModal('login')
  }, []) // eslint-disable-line

  return (
    <>
      <FulfillmentOptionsNew
        merchant={merchant}
        stores={stores}
        onClose={onClose}
      />
    </>
  )
}

const LandingHeader = styled.header(({ theme }: any) => ({
  padding: '22px 22px 36px',
  backgroundColor: theme.colors['white'],
  zIndex: theme.zIndex['stickyHeader'],
  borderRadius: '24px',
  [theme.mediaQueries.viewport5]: {
    fontSize: '22px',
    padding: '32px 40px 30px'
  }
}))

const LandingTitle = styled.h1(({ theme }: any) => ({
  display: 'flex',
  fontSize: '20px',
  fontWeight: 600,
  justifyContent: 'space-between',
  [theme.mediaQueries.viewport5]: {
    fontSize: '22px'
  }
}))

const LandingSubtitle = styled.h2(() => ({
  paddingTop: '8px',
  fontWeight: 400,
  fontSize: '16px',
  color: '#595959'
}))

const ModalCloseButtonContainer = styled.div<
  StyledHTMLElement & { allStoresClosed?: boolean },
  Required<Theme>
>(({ theme, allStoresClosed }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...(allStoresClosed && {
    padding: '16px 0',
    [theme.mediaQueries.viewport5]: {
      padding: 0,
      justifyContent: 'flex-end',
      position: 'absolute',
      top: '25px',
      right: '25px'
    }
  })
}))

const LandingContainer = styled.section(({ theme }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: `0 16px 0`,
  [theme.mediaQueries.viewport5]: {
    padding: `0 ${theme.space[6]}px 0`
  }
}))

const ClosedStoresMessage = styled.h2(({ theme }: any) => ({
  color: theme.colors.textBold,
  fontWeight: theme.fonts.heading.weight,
  fontFamily: theme.fonts.heading.family,
  marginTop: theme.space[4],
  [theme.mediaQueries.viewport7]: {
    fontSize: theme.fontSizes[5],
    paddingRight: '10%'
  }
}))

export const Divider = styled.hr(({ theme }: any) => ({
  margin: `${theme.space[5]}px 0`,
  border: 0,
  borderBottom: '1px solid #f5f5f5',

  [theme.mediaQueries.viewport7]: {
    display: 'none'
  }
}))

export default LandingFormNew
