import styled from '@emotion/styled'
import { Theme } from 'styled-system'
import { StyledHTMLElement } from 'shop/theme/types'

export const Divider = styled.hr(({ theme }: any) => ({
  margin: `${theme.space[5]}px 0`,
  border: 0,
  borderBottom: '1px solid #f5f5f5',

  [theme.mediaQueries.viewport7]: {
    display: 'none'
  }
}))

export const Title = styled.h1<StyledHTMLElement, Required<Theme>>(
  ({ theme }: any) => ({
    fontSize: '20px',
    fontFamily: theme.fonts.heading.family,
    [theme.mediaQueries.viewport6]: {
      fontSize: '24px'
    },
    fontWeight: 700
  })
)
