import {
  VALIDATE_CART,
  MUTATE_UPDATE_CART_TIMESLOT,
  MUTATE_REFRESH_CART,
  GET_PAY_NEXT_STEP
} from 'shop/client'
import { ApolloClient } from '@apollo/client'
import { CustomerDetailsParams } from 'shop/types/cart'
import { CartValidation } from './types'

interface ValidationApiResult {
  data: {
    validateCart: CartValidation
  }
}

const getPayNextStep = async (client: ApolloClient<object>, cartId: string) => {
  return client.mutate({
    mutation: GET_PAY_NEXT_STEP,
    variables: { cartId }
  })
}

export type UpdateDateCartRecipientDetails = Omit<
  CustomerDetailsParams,
  'email' | 'contactNumPrefix'
>

const refreshCart = (client: ApolloClient<object>, cartId: string) => {
  return client.mutate({
    mutation: MUTATE_REFRESH_CART,
    variables: {
      cartId
    }
  })
}

const validateCart = (
  client: ApolloClient<object>,
  cartId: string,
  increaseCutoffMinutes?: number
): Promise<ValidationApiResult> => {
  return client.query({
    query: VALIDATE_CART,
    variables: { cartId, increaseCutoffMinutes },
    fetchPolicy: 'network-only'
  })
}

const updateCartTimeslot = (
  client: ApolloClient<object>,
  cartId: string,
  deliverBy: string | null,
  fulfillmentTimeRange: string | null
) => {
  return client.mutate({
    mutation: MUTATE_UPDATE_CART_TIMESLOT,
    variables: { deliverBy, fulfillmentTimeRange, cartId }
  })
}

export { refreshCart, getPayNextStep, validateCart, updateCartTimeslot }
