import React from 'react'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { Button, CartProductErrorCode } from 'shop/components'
import CenterModal from 'shop/components/Modal/CenterModal'
import CloseButton from 'shop/components/Modal/CloseButton'
import { LineBreak, overlayOpenAnim } from 'shop/components/Shop/commonStyles'
import Spinner from 'shop/components/Loader/Spinner'
import ProductInfoItemV2 from 'shop/components/Modal/ProductInfoItemV2'
import { itemScollListShadows, thinScrollbar } from 'shop/components/common'
import {
  CartErrorMessage,
  ExtendedOrderItemV2,
  OrderItemV2
} from 'shop/types/cart'
import { Origin, SHOP_ORIGIN } from 'shop/types'

type Props = {
  invalidItems: (OrderItemV2 | ExtendedOrderItemV2)[]
  onContinue: (e: React.MouseEvent) => void
  storeName: string
  productErrorCode: CartProductErrorCode
  isOpen?: boolean
  origin: Origin
  onClose?: () => void
  loading?: boolean
}

const LoadingState = React.memo(() => (
  <SpinnerContainer>
    <Spinner size='48px' />
  </SpinnerContainer>
))

const Cta = React.memo(
  ({
    onContinue,
    onClose,
    isShopOrigin
  }: {
    onContinue: (e: React.MouseEvent) => void
    onClose?: () => void
    isShopOrigin: boolean
  }) => (
    <>
      <Button
        uppercase={false}
        onMouseDown={(e) => e.preventDefault()}
        onClick={onContinue}
      >
        Continue
      </Button>
      {isShopOrigin && (
        <BackContainer onClick={onClose}>
          <Back>Go back</Back>
        </BackContainer>
      )}
    </>
  )
)

const CartProductErrorModalV2 = ({
  onClose,
  invalidItems,
  onContinue,
  storeName,
  productErrorCode,
  origin,
  isOpen = true,
  loading = false
}: Props) => {
  if (!invalidItems.length) return <></>

  const isShopOrigin = origin === SHOP_ORIGIN
  const isCartProductError =
    productErrorCode === CartErrorMessage.PRODUCT_PRICES_CHANGED
  const centreModalProps =
    origin === SHOP_ORIGIN ? { inModal: true, isOverlay: true, isOpen } : {}
  const hasMultipleItems = invalidItems.length > 1

  const productErrorCopyMap = {
    [CartErrorMessage.PRODUCT_PRICES_CHANGED]: {
      header: `${hasMultipleItems ? 'Some prices have changed' : 'Price change'}`,
      body: `The following product${hasMultipleItems ? 's have' : ' has'} changed. To accept these changes, click continue.`
    },
    [CartErrorMessage.PRODUCTS_OUT_OF_STOCK]: {
      header: `${hasMultipleItems ? 'Some products are out of stock' : 'Product out of stock'}`,
      body: `Unfortunately, the following product${hasMultipleItems ? 's are' : ' is'} out of stock in the ${storeName} store. Continuing will remove ${hasMultipleItems ? 'them' : 'it'} from your basket.`
    },
    [CartErrorMessage.INVALID_MODIFIERS]: {
      header: `Unavailable Modifiers`,
      body: `Unfortunately, the following product${hasMultipleItems ? 's have' : ' has'} unavailable modifier${hasMultipleItems ? 's' : '(s)'}. Continuing will remove ${hasMultipleItems ? 'them' : 'the product'} from your basket.`
    },
    [CartErrorMessage.PRODUCTS_UNAVAILABLE]: {
      header: `${hasMultipleItems ? 'Unavailable products' : `Unavailable product`}`,
      body: `Unfortunately, the following product${hasMultipleItems ? 's are' : ' is'} no longer available in the ${storeName} store. Continuing will remove ${hasMultipleItems ? 'them' : 'it'} from your basket.`
    }
  }

  const InvalidProducts = () => (
    <Container>
      <Header>{productErrorCopyMap[productErrorCode].header}</Header>
      <Text>{productErrorCopyMap[productErrorCode].body}</Text>
      <ItemContainer isScrollable={invalidItems.length > 2}>
        {invalidItems.map((item, index) => {
          const isLast = invalidItems.length === index + 1
          return (
            <React.Fragment key={`${item.id}-${index}`}>
              <ProductInfoItemV2
                productErrorCode={productErrorCode}
                item={item}
                showPrice={isCartProductError}
              />
              {!isLast && <LineBreak />}
            </React.Fragment>
          )
        })}
      </ItemContainer>
      <Cta
        onContinue={onContinue}
        onClose={onClose}
        isShopOrigin={isShopOrigin}
      />
    </Container>
  )

  return (
    <CenterModal
      styles={overlayOpenAnim(isOpen)}
      canClose={false}
      {...centreModalProps}
    >
      <>
        {loading ? (
          <LoadingState />
        ) : (
          <>
            {isShopOrigin && (
              <CloseContainer>
                <CloseButton
                  handleCloseModal={onClose}
                  variant='newFulfillmentModal'
                />
              </CloseContainer>
            )}
            <InvalidProducts />
          </>
        )}
      </>
    </CenterModal>
  )
}

const Container = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '32px',
  gap: '24px',
  justifyContent: 'center',
  '& >button': {
    borderRadius: '12px'
  },
  '> p': { margin: 0 }
}))

const CloseContainer = styled.div<StyledHTMLElement>(() => ({
  '> div': {
    position: 'absolute',
    top: '24px',
    right: '24px'
  }
}))

const Text = styled.p<StyledHTMLElement>(() => ({
  fontSize: '16px',
  lineHeight: '24px',
  color: '#595959'
}))

const Header = styled.h1<StyledHTMLElement>(() => ({
  fontSize: '24px',
  alignSelf: 'left'
}))

const ItemContainer = styled.ul<
  StyledHTMLElement & { isScrollable: boolean },
  Required<Theme>
>(({ theme, isScrollable }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '8px',
  flexDirection: 'column',
  maxHeight: '175px', // ~70px per item - 2.5 items
  ...(isScrollable
    ? {
        overflowY: 'auto',
        marginRight: '-10px',
        paddingRight: '10px',
        ...(itemScollListShadows(theme) as any),
        [theme['mediaQueries']['viewport7']]: {
          marginRight: '-15px',
          paddingRight: '5px'
        },
        ...(thinScrollbar(theme) as any)
      }
    : {})
}))

const Back = styled.span<StyledHTMLElement>(() => ({
  fontWeight: 500,
  color: '#595959',
  margin: 0
}))

const BackContainer = styled.div<StyledHTMLElement>(() => ({
  width: 'auto',
  margin: '0 auto',
  padding: '0px 10px',
  cursor: 'pointer'
}))

const SpinnerContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }: any) => ({
    height: '100%',
    padding: '152px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: theme.fontWeights.bold,
    color: 'lightgrey'
  })
)

export default CartProductErrorModalV2
