import { ApolloClient } from '@apollo/client'
import { LOCK_CART } from 'shop/client'

type UsePayProps = {
  client: ApolloClient<object>
  cartId: string
}

export const usePay = ({ client, cartId }: UsePayProps) => {
  /** "Locks" or "Unlocks" a cart so that it can't manipulated.
   * Primarily used when finalising payments.
   */
  const lockCart = (lock: boolean) =>
    client
      .mutate({
        mutation: LOCK_CART,
        variables: {
          cartId,
          lock
        }
      })
      .then(({ data }) => {
        return data.payForCart.nextStep as string
      })

  return { lockCart }
}
