import CartBreakdownItem from './CartBreakdownItem'
import { AiOutlineHeart as Heart } from 'react-icons/ai'
import { isTipMoreThanZero } from 'shop/components/Tipping/utils'
import { useCheckout } from 'shop/hooks'

type Props = {
  tip: string | null
  isCartLoading: boolean
  isEditable?: boolean
}

const TipBreakdown = ({ tip, isCartLoading, isEditable = false }: Props) => {
  const { openTippingModal } = useCheckout()
  const hasTip = !!tip && isTipMoreThanZero(tip)

  return (
    <CartBreakdownItem
      LeftIcon={Heart}
      label={'Tip'}
      price={hasTip ? { base: tip } : null}
      isCartLoading={isCartLoading}
      withLineBreak={false}
      padding={'0px'}
      maxWidth={hasTip ? undefined : '100%'}
      onChangeButtonClick={isEditable ? openTippingModal : undefined}
      changeButtonText={!!hasTip ? 'Update' : 'Add tip'}
    />
  )
}

export default TipBreakdown
