import React, { useCallback, useEffect, useState } from 'react'

import { useLoader, useShop, useSlerpMerchant } from 'shop/hooks'
import { Partner } from 'shop/components/Landing'
import { SlerpMerchantQueryResponse } from 'shop/hooks/useGlobalContext/types'
import { LoaderProps } from './types'

export const SlerpMerchantLoader = ({
  isParentLoaded = true,
  children
}: LoaderProps) => {
  const { fetchSlerpMerchant } = useLoader()
  const { setSlerpMerchant } = useSlerpMerchant()
  const { partner, isPartnerLoading } = useShop()

  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const fetchData = useCallback(async () => {
    const slerpCartResult = await fetchSlerpMerchant()

    if (slerpCartResult) {
      setSlerpMerchant(slerpCartResult)
    }

    setIsLoaded(Boolean(slerpCartResult) && isParentLoaded)
  }, [fetchSlerpMerchant, setSlerpMerchant, isParentLoaded])

  const mapPartnerToSlerpMerchant = (
    partner: Partner
  ): SlerpMerchantQueryResponse => {
    const tempSlerpMerchant: SlerpMerchantQueryResponse = {
      tagline: partner.seo.tagline,
      seoDescription: partner.seo.description,
      isSeoEnabled: partner.seo.enabled
    }
    return tempSlerpMerchant
  }
  useEffect(() => {
    if (isPartnerLoading || !isParentLoaded) return
    if (!partner) {
      fetchData()
      return
    }
    const mappedSlerpMerchant = mapPartnerToSlerpMerchant(partner)
    setSlerpMerchant(mappedSlerpMerchant)
    setIsLoaded(!!isParentLoaded)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParentLoaded, partner, isPartnerLoading])

  return <React.Fragment>{children(isLoaded)}</React.Fragment>
}
