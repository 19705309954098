import styled from '@emotion/styled'
import { InputGroup } from '../Controls'
import { Fields } from './FormElements'
import { UseFormMethods } from 'react-hook-form'
import { FormFields } from './types'
import AlertMessage from '../Cart/AlertMessage'
import {
  focusNextInput,
  CUSTOMER_INPUTS,
  handlePhoneNumberOnChange,
  handlePhoneNumberOnBlur
} from './utils'
import useInputKeyEnterHandler from 'shop/hooks/useInputKeyEnterHandler'
import FormInput from '../Inputs/FormInput'
import { HalfWidthFlexContainer } from './commonStyles'
import { emailRegex } from 'shop/utils/common'
import { IntlPhoneNumber } from '../Inputs'
import { useCheckout } from 'shop/hooks'

type Props = { formHandle: UseFormMethods<FormFields>; onBlur: () => void }

const CustomerDetailsForm = ({ formHandle, onBlur }: Props) => {
  const { register, errors, watch, setValue, trigger } = formHandle
  const detailsErrors = errors['customerDetails']

  const { trackAddShippingInfo } = useCheckout()

  const {
    firstNameInputId,
    lastNameInputId,
    emailInputId,
    phonePrefixId,
    phoneInputId
  } = CUSTOMER_INPUTS

  const customerDetails = watch('customerDetails')

  const handleBlur = () => {
    onBlur()
    // sending tracking event, only if all fields are filled
    if (
      !!customerDetails.firstName.length &&
      !!customerDetails.lastName.length &&
      !!customerDetails.email.length &&
      !!customerDetails.contactNumber.length
    ) {
      trackAddShippingInfo()
    }
  }

  useInputKeyEnterHandler({
    handleKeyPress: () =>
      focusNextInput([
        firstNameInputId,
        lastNameInputId,
        emailInputId,
        phoneInputId
      ])
  })

  const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.toLowerCase()
  }

  const handlePhoneInputsBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handlePhoneNumberOnBlur(
      e,
      setValue,
      trigger,
      'customerDetails.contactNumber'
    )
    handleBlur()
  }

  return (
    <div>
      {detailsErrors && (
        <AlertMessageWrapper>
          <AlertMessage
            skin='mild'
            type='error'
            heading={Object.values(detailsErrors)[0]?.message || ''}
          />
        </AlertMessageWrapper>
      )}

      <Fields>
        <InputGroup templateColumns='1fr' gridGap='24px'>
          <HalfWidthFlexContainer>
            <FormInput
              id={firstNameInputId}
              labelText='First Name'
              name={firstNameInputId}
              autoComplete='given-name'
              value={customerDetails?.firstName || ''}
              onBlur={handleBlur}
              showError={!!errors?.customerDetails?.firstName}
              formRef={register({
                required: 'Please enter your first name',
                validate: (value: string) =>
                  value.trim().length > 0
                    ? true
                    : 'Please enter your first name'
              })}
            />
            <FormInput
              id={lastNameInputId}
              name={lastNameInputId}
              value={customerDetails?.lastName || ''}
              labelText='Last Name'
              autoComplete='family-name'
              onBlur={handleBlur}
              showError={!!errors?.customerDetails?.lastName}
              formRef={register({
                required: 'Please enter your last name',
                validate: (value: string) =>
                  value.trim().length > 0 ? true : 'Please enter your last name'
              })}
            />
          </HalfWidthFlexContainer>
          <FormInput
            id={emailInputId}
            name={emailInputId}
            labelText='Email Address'
            value={customerDetails?.email || ''}
            autoComplete='email'
            onBlur={handleBlur}
            onChange={handleEmailInputChange}
            showError={!!errors?.customerDetails?.email}
            inputMode='email'
            formRef={register({
              required: 'Please enter your email address',
              pattern: {
                value: emailRegex,
                message: 'Invalid email address'
              }
            })}
          />
          <IntlPhoneNumber
            onNumberChange={handlePhoneNumberOnChange}
            onBlur={handlePhoneInputsBlur}
            formHandle={formHandle}
            prefixDropdownId={phonePrefixId}
            labelText='Phone Number'
            prefixDropdownValue={customerDetails?.contactNumPrefix}
            prefixDropdownName={'customerDetails.contactNumPrefix'}
            phoneNumberInputName={'customerDetails.contactNumber'}
            phoneNumberInputId={phoneInputId}
            phoneNumberValue={customerDetails?.contactNumber || ''}
            showError={
              !!errors?.customerDetails?.contactNumber ||
              !!errors?.customerDetails?.contactNumPrefix
            }
          />
        </InputGroup>
      </Fields>
    </div>
  )
}

const AlertMessageWrapper = styled.div(() => ({
  margin: '24px 0'
}))

export default CustomerDetailsForm
