import { format } from 'date-fns'
import _ from 'lodash'
import { parseTimezoneISO } from './date'
import { FulfillmentWindow } from 'shop/types'
import { ApolloError } from '@apollo/client'
import { Store } from 'shop/components/Landing'
import { History } from 'history'

export const getErrorMessage = (graphQLErrors: any): string => {
  return (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) || ''
}

export const isNetworkErrorUnauthorized = (networkError: any): boolean => {
  return !!(
    networkError &&
    networkError.statusCode === 401 &&
    (networkError.bodyText === 'unauthorized' ||
      networkError.result === 'unauthorized')
  )
}

export const checkHandleUnauthorizedError = (
  error: ApolloError,
  domain: string,
  currentStore: Store | undefined,
  history: History
) => {
  const { networkError, graphQLErrors } = error

  const isUnauthorized =
    (networkError && isNetworkErrorUnauthorized(networkError)) ||
    (graphQLErrors &&
      ['unauthorized', 'auth_missing'].includes(getErrorMessage(graphQLErrors)))

  if (isUnauthorized) {
    // If unauth'd clear cart related credentials & return to shop
    localStorage.removeItem(domain)
    localStorage.removeItem(`${domain}-csrf`)
    returnToShop(currentStore, history)
    return
  }
}

export const returnToShop = (
  currentStore: Store | undefined,
  history: History
) => {
  !!currentStore
    ? history.push(`/store/${currentStore.slug}`)
    : history.push('/')
}

/** The 'keysHaveValues' function checks if any of the specified keys in the object have non-empty values.
 * returns true if any of the specified keys have non-empty values, false otherwise. */
export const keysHaveValues = (
  keys: string[],
  obj: Record<string, any>
): boolean => {
  return _.some(keys, (key) => !_.isEmpty(obj[key]))
}

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

/** Returns fulfillment time range. Example output: "14:00 - 14:30" */
export const formatFulfillmentWindow = (window: FulfillmentWindow): string =>
  `${format(
    parseTimezoneISO(window.from),
    'HH:mm'
  )} - ${format(parseTimezoneISO(window.to), 'HH:mm')}`

export const getLogoURL = (domain: string, assetHost: string) =>
  `https://${domain}.${assetHost}/assets/logo/alternate`

export const getImageURL = (
  domain: string,
  assetHost: string,
  variantID: string
) => `https://${domain}.${assetHost}/assets/variant/${variantID}`

/** Move a list of given keys to a new property within the same object  */
export const mapKeysToNewProperty = (
  obj: Object,
  keysToExtract: string[],
  newKey: string
) => {
  // Empty object to hold the extracted keys.
  const extractedKeys = {}
  // Mutable object
  const tempObj = { ...obj }

  // Loops through the keys to extract and delete them from the original object.
  keysToExtract.forEach((key) => {
    if (key in tempObj) {
      extractedKeys[key] = tempObj[key]
      delete tempObj[key]
    }
  })

  // Assign the object of extracted keys to a new key within the original object.
  tempObj[newKey] = extractedKeys

  return tempObj
}
