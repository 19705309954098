import { useState } from 'react'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import CenterModal from '../Modal/CenterModal'
import TippingListV2 from './TippingListV2'
import { Button } from '../Controls'
import { TipValues } from 'shop/types/cart'
import { useMediaQueries } from 'shop/hooks'
import { findMarginTopForCenter, isTipMoreThanZero } from './utils'

type Props = {
  tippingValues: TipValues[]
  handleSubmitTip: (tipAmount: string) => void
  currentTipValue: string | null
}

export type TipType = 'custom' | 'standard'

const TippingV2 = ({
  tippingValues,
  handleSubmitTip,
  currentTipValue
}: Props) => {
  const [tipAmount, setTipAmount] = useState<string>('0.00')
  // TipType clears other selected options
  const [tipType, setTipType] = useState<TipType>('standard')
  const { isMobile } = useMediaQueries()

  const hasTip = isTipMoreThanZero(currentTipValue)

  return (
    <CenterModal
      modalId='tipping-modal'
      canClose={false}
      styles={{
        padding: isMobile ? '24px 16px 16px 16px' : '40px 32px 32px 32px',
        borderRadius: '20px',
        maxWidth: 'fit-content',
        ...(isMobile ? { margin: `${findMarginTopForCenter()}px auto` } : {})
      }}
      modalAlign={isMobile ? 'flex-start' : 'center'}
    >
      <TippingContainer data-testid='tipping-container'>
        <TippingHeader>
          <TippingTextContainer>
            <h1>Would you like to add a tip?</h1>
            <h2>Your tip goes to our amazing staff</h2>
          </TippingTextContainer>
        </TippingHeader>
        <TippingListV2
          tippingValues={tippingValues}
          setTipAmount={setTipAmount}
          tipType={tipType}
          setTipType={setTipType}
          currentTipValue={currentTipValue}
        />
        <ButtonContainer>
          <Button
            variant='secondary'
            onClick={() => handleSubmitTip('0.00')}
            fontColor='inherit'
          >
            {hasTip ? 'Remove tip' : 'Skip for now'}
          </Button>
          <Button variant='primary' onClick={() => handleSubmitTip(tipAmount)}>
            {hasTip ? 'Update tip' : 'Add tip'}
          </Button>
        </ButtonContainer>
      </TippingContainer>
    </CenterModal>
  )
}

const TippingContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',

    [theme.mediaQueries.viewport7]: {
      gap: '52px'
    }
  })
)
const TippingHeader = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    gap: '20px'
  })
)

const TippingTextContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: '12px',
    justifyContent: 'center',

    '& h1': {
      fontSize: '20px',

      [theme.mediaQueries.viewport7]: {
        fontSize: '24px'
      }
    },
    '& h2': {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '20px',
      color: '#000'
    }
  })
)

const ButtonContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    gap: '20px',
    fontSize: '16px',
    color: theme.colors['primary'],

    '& button': {
      fontWeight: 600
    },
    '& button:first-of-type': {
      maxWidth: '40%'
    }
  })
)

export default TippingV2
