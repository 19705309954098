import React, { useEffect, useRef, useState } from 'react'
import { IoChevronDownOutline } from 'react-icons/io5'
import { SearchableDropdown } from '.'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { IconBaseProps } from 'react-icons'
import styled from '@emotion/styled'
import { useLabelOffset } from 'shop/hooks/useLabelOffset'
import { scrollToElement } from '../Controls/utils'
import { InputLine, PlaceholderToLabelText, TextInput } from './common'
import { default as countryCodes } from 'shop/countryCodes.json'
import { strippedContactNumPrefix, matchCountryDialCode } from './utils'
import { UseFormMethods } from 'react-hook-form'
import { CountryCode } from 'shop/types'
import parsePhoneNumberFromString, { isValidNumber } from 'libphonenumber-js'
import { Medium } from '../common'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  showError: boolean
  onNumberChange: React.ChangeEventHandler<HTMLInputElement>
  onBlur: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  prefixDropdownValue: string
  prefixDropdownId: string
  prefixDropdownName: string
  labelText: string
  phoneNumberValue: string
  phoneNumberInputName: string
  phoneNumberInputId: string
  formHandle: UseFormMethods<any>
}

const IntlPhoneNumber = ({
  formHandle,
  prefixDropdownName,
  prefixDropdownId,
  onBlur,
  showError,
  prefixDropdownValue,
  onNumberChange,
  name,
  labelText,
  phoneNumberValue,
  phoneNumberInputName,
  phoneNumberInputId,
  ...inputProps
}: Props) => {
  const { register, setValue, trigger } = formHandle
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [areInputsFocused, setAreInputsFocused] = useState(false)
  const [hasStartedTyping, setHasStartedTyping] = useState(false)
  const defaultCountryCode = countryCodes[0]
  const prevCountryCodeRef = useRef<string>(
    prefixDropdownValue ||
      `${defaultCountryCode.flag} ${defaultCountryCode.dial_code}`
  )
  const dropdownInputRef: React.MutableRefObject<HTMLInputElement | null> =
    useRef(null)

  /** If value is undefined set to default value on init */
  useEffect(() => {
    if (!prefixDropdownValue) {
      setValue(
        prefixDropdownName,
        `${defaultCountryCode.flag} ${defaultCountryCode.dial_code}`
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { labelOffsetLeft, labelOffsetTop, labelOffsetTopFocused } =
    useLabelOffset(phoneNumberInputId, true)

  const handlePhoneNumberBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (onBlur) {
      onBlur(e)
    }
    setAreInputsFocused(false)
  }

  const onFocus = () => {
    phoneNumberInputId && scrollToElement(`${phoneNumberInputId}`, 80)
    setAreInputsFocused(true)
  }

  const handleDropdownOptionClick =
    (option: CountryCode) => async (e: React.MouseEvent) => {
      e.preventDefault()
      setIsDropdownOpen(false)
      await setValue(prefixDropdownName, `${option.flag} ${option.dial_code}`)
      // Store the previous country code for fallback value if user types invalid country code
      prevCountryCodeRef.current = `${option.flag} ${option.dial_code}`
      retriggerInputValidations()
      setHasStartedTyping(false)
    }

  const handleDropdownBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    const strippedCountryCode = strippedContactNumPrefix(e.target.value)
    const countryCode = matchCountryDialCode(strippedCountryCode)
    if (countryCode) {
      await setValue(
        prefixDropdownName,
        `${countryCode.flag} ${countryCode.dial_code}`
      )
      prevCountryCodeRef.current = `${countryCode.flag} ${countryCode.dial_code}`
    } else {
      await setValue(prefixDropdownName, prevCountryCodeRef.current)
    }
    retriggerInputValidations()
    setHasStartedTyping(false)
    setAreInputsFocused(false)
    setIsDropdownOpen(false)
  }

  const retriggerInputValidations = () => {
    trigger(prefixDropdownName)
    trigger(phoneNumberInputName)
  }

  const handleDropdownInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHasStartedTyping(true)
    setIsDropdownOpen(true)
    // Handles case where autofill is used, a change is triggered
    if (
      dropdownInputRef.current &&
      document.activeElement !== dropdownInputRef.current
    ) {
      // Create synthetic FocusEvent
      const syntheticEvent = {
        target: dropdownInputRef.current
      } as React.FocusEvent<HTMLInputElement>

      // Trigger handleDropdownBlur with synthetic event
      handleDropdownBlur(syntheticEvent)
    }
  }

  /** Force cursor to start of input */
  const handleDropdownInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.setSelectionRange(0, 0)
  }

  /** Clear value on initial keydown event */
  const handleDropdownKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!hasStartedTyping) {
      setValue(prefixDropdownName, '')
    }
  }

  const filterCountryCodes = (options: CountryCode[]) => {
    const strippedCountryCode = strippedContactNumPrefix(prefixDropdownValue)
    return options.filter((option: CountryCode) => {
      return (
        option.dial_code.indexOf(strippedCountryCode) > -1 ||
        option.name.toLowerCase().indexOf(strippedCountryCode) > -1
      )
    })
  }

  const preventEventBubbling = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
  }

  // Filter country codes based on user input
  const suggestions = filterCountryCodes(countryCodes)
  const remainingCountries = countryCodes.filter(
    (option) => !suggestions.includes(option)
  )

  const renderDropdownOptions = (options: CountryCode[], keyPrefix: string) =>
    options.map((option, index) => (
      <DropdownOption
        onClick={handleDropdownOptionClick(option)}
        onMouseDown={preventEventBubbling}
        key={`${phoneNumberInputId}-dropdown-${keyPrefix}-${index}`}
      >
        {option.flag} {option.name} <span>{option.dial_code}</span>
      </DropdownOption>
    ))

  return (
    <InputLine>
      <SearchableDropdownContainer
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <SearchableDropdown
          name={prefixDropdownName}
          id={`${phoneNumberInputId}-dropdown`}
          dropdownInputRef={dropdownInputRef}
          formRef={register()}
          isOpen={isDropdownOpen}
          type='text'
          autoComplete='not-this-one'
          onFocus={onFocus}
          isInputFocused={areInputsFocused}
          onBlur={handleDropdownBlur}
          onClick={handleDropdownInputClick}
          showError={showError}
          onKeyDown={handleDropdownKeyDown}
          onChange={handleDropdownInputChange}
          additionalStyles={{
            borderRadius: '12px 0 0 12px',
            borderRight: 'none'
          }}
          {...inputProps}
        >
          {prefixDropdownValue && !!suggestions.length ? (
            <>
              <DropdownOption
                onClick={preventEventBubbling}
                onMouseDown={preventEventBubbling}
              >
                <Medium>Suggestions</Medium>
              </DropdownOption>
              {renderDropdownOptions(suggestions, 'suggestions')}
              <DropdownOption
                onClick={preventEventBubbling}
                onMouseDown={preventEventBubbling}
              >
                <Medium>All Countries</Medium>
              </DropdownOption>
              {renderDropdownOptions(remainingCountries, 'rest')}
            </>
          ) : (
            renderDropdownOptions(countryCodes, 'all')
          )}
        </SearchableDropdown>
        <Chevron rotate={isDropdownOpen ? '180deg' : '0deg'} />
      </SearchableDropdownContainer>
      <TextInput
        id={phoneNumberInputId}
        name={phoneNumberInputName}
        isInputFocused={areInputsFocused}
        data-testid={`${phoneNumberInputId}-input`}
        type='tel'
        autoComplete='tel-national'
        onChange={onNumberChange}
        onFocus={onFocus}
        onBlur={handlePhoneNumberBlur}
        hasPlaceholderPreText={true}
        isPassword={false}
        showError={showError}
        ref={register({
          required: 'Please enter your phone number',
          validate: {
            validNumber: (value) => {
              const prefixDialCode =
                strippedContactNumPrefix(prefixDropdownValue)
              const phoneNumber = parsePhoneNumberFromString(
                `${prefixDialCode}${value}`
              )
              if (phoneNumber) {
                const countryCode = phoneNumber.country
                return isValidNumber(value, countryCode) || 'Not a valid number'
              }
              return 'Not a valid number'
            }
          }
        })}
        {...inputProps}
      />
      <PlaceholderToLabelText
        id={`${phoneNumberInputId}-placeholder`}
        htmlFor={phoneNumberInputId}
        isInputFocused={areInputsFocused}
        hasValue={!!phoneNumberValue?.length}
        labelOffsetLeft={labelOffsetLeft}
        labelOffsetTop={labelOffsetTop}
        labelOffsetTopFocused={labelOffsetTopFocused}
        showError={showError}
      >
        {labelText}
      </PlaceholderToLabelText>
    </InputLine>
  )
}

const Chevron = styled(IoChevronDownOutline)<
  IconBaseProps & { rotate: string }
>(({ rotate }) => ({
  width: '18px',
  height: '18px',
  alignSelf: 'center',
  position: 'absolute',
  left: '86px',
  display: 'flex',
  transition: 'all 100ms ease-out',
  transform: `rotate(${rotate})`
}))

const SearchableDropdownContainer = styled.div<StyledHTMLElement>(() => ({
  display: 'flex'
}))

const DropdownOption = styled.div<StyledHTMLElement, Required<Theme>>({
  display: 'flex',
  padding: '14px 12px',
  justifyContent: 'space-between',
  cursor: 'pointer',
  borderBottom: '1px solid #D9D9D9',
  '&:hover': {
    backgroundColor: '#F4F4F4'
  }
})

export default IntlPhoneNumber
