import React, {
  useEffect,
  useReducer,
  useContext,
  useState,
  MutableRefObject,
  useRef,
  useMemo
} from 'react'
import {
  useCart,
  useLoader,
  useModal,
  useReactRouter,
  useSlerpCart
} from 'shop/hooks'
import styled from '@emotion/styled'
import { CartItem, FulfillmentProps } from './types'
import { DeliveryFormNew, DeliverySignIn } from './Delivery'
import { LandingContextNew } from './LandingContext'
import { CurrentLocationState } from 'shop/components'
import {
  dateString,
  timeString,
  isFulfillmentTypeEnabled,
  transformOrderItems,
  returnInvalidCartItems,
  fadeInOutMs,
  trackFulfillmentTypeChange
} from 'shop/components/Landing/utils'
import { useUTMTracker } from './trackingUtils'
import { validateAddressDataForSaving } from './addressUtils'
import { getStoreUrl } from 'shop/utils/store'
import SaveAddressModal from './SaveAddressModal'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import SelectablePillNew from './SelectablePillNew'
import { addDays } from 'date-fns'
import { BaseAddress, ConsumerCartPayload, OrderItem } from 'shop/types/cart'
import {
  ApiError,
  DeliveryAddress,
  FulfillmentType,
  InitSlerpCartProps
} from 'shop/types'
import CartProductErrorModal from './Delivery/CartProductErrorModal'
import { Button } from '../Controls'
import { useConsumerCart } from 'shop/hooks/useConsumerCart'
import { constructDeliveryAddress } from 'shop/utils'

const FulfillmentOptionsNew = (props: FulfillmentProps) => {
  const landingContext = useContext(LandingContextNew)
  const cart = useCart()
  const { history } = useReactRouter()
  const { merchant, stores } = props
  const { tracker } = useUTMTracker()
  const { fetchSlerpCart } = useLoader()
  const { setSlerpCart } = useSlerpCart()
  const { initConsumerCart } = useConsumerCart()
  const customerId = localStorage.getItem('customerId')
  const isLoggedIn = customerId !== null
  const { pickup_address_enabled } = merchant
  const [showSaveAddressModal, setShowSaveAddressModal] =
    useState<boolean>(false)

  const { loadCart, cartSession } = cart

  const [orderItems] = useState<OrderItem[]>([])
  const [isSwitchingFulfillment, setIsSwitchingFulfillment] =
    useState<boolean>(false)

  const [hasCartProductModalError, setHasCartProductModalError] =
    useState<boolean>(false)
  const [cartProductModalErrorType, setCartProductModalErrorType] = useState<
    'InvalidItems' | 'PriceChange' | null
  >(null)
  const [invalidItems, setInvalidItems] = useState<CartItem[]>([])

  const {
    fulfillmentDate,
    fulfillmentType,
    setFulfillmentType,
    fulfillmentTime,
    currentLocation,
    currentStore,
    inModal,
    addToCart,
    useCustomerDefaultAddress,
    isExtendedHour,
    setShowFulfillmentOptions,
    isEditMode,
    validStores,
    isOverlayOpen,
    setIsOverlayOpen,
    isLoginOpen,
    setIsLoginOpen,
    setCurrentLocation,
    setAddress,
    loadingDates,
    loadingStores
  } = landingContext

  const deliveryEnabled = isFulfillmentTypeEnabled(stores, 'delivery')
  const datesOrStoresLoading = useMemo(
    () => loadingDates[fulfillmentType] || loadingStores[fulfillmentType],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingDates[fulfillmentType], loadingStores[fulfillmentType]]
  )

  const { closeModal } = useModal()

  useEffect(() => {
    const defaultFulfillment = isEditMode
      ? fulfillmentType
      : deliveryEnabled
        ? 'delivery'
        : 'pickup'
    setFulfillmentType(defaultFulfillment)
    SwitchType(defaultFulfillment)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryEnabled, setFulfillmentType])

  const FulfillmentSetting = {
    Form: <></>,
    onReady: () => {},
    type: ''
  }

  const selectFulfillment = (state: any, type: string) => {
    return {
      type: type,
      Form: <DeliveryFormNew />
    }
  }

  const [Fulfillment, SwitchType] = useReducer(
    selectFulfillment,
    FulfillmentSetting
  )

  const createSlerpCartParams = (): InitSlerpCartProps => {
    return {
      customerId,
      storeId: currentStore && currentStore.id,
      merchantId: merchant && merchant.id,
      address: currentLocation ? currentLocation.address : '',
      fulfillmentDate: fulfillmentDate && fixFulfillmentDate(),
      fulfillmentTime: fixFulfillmentTime(),
      fulfillmentType,
      orderItems,
      metadata: JSON.stringify({
        referral: tracker
      }),
      deliveryAddress: constructDeliveryAddress(
        currentLocation,
        fulfillmentType
      ) as DeliveryAddress
    }
  }

  const createConsumerCartParams = (): ConsumerCartPayload | null => {
    if (!currentStore) return null
    return {
      storeId: currentStore && currentStore.id,
      fulfillmentDate: fulfillmentDate && fixFulfillmentDate(),
      fulfillmentTime: fixFulfillmentTime(),
      fulfillmentType: fulfillmentType.toUpperCase() as FulfillmentType,
      deliveryAddress: constructDeliveryAddress(
        currentLocation,
        fulfillmentType,
        { consumerApi: true }
      ) as BaseAddress
    }
  }

  const fixFulfillmentDate = () => {
    const date = isExtendedHour ? addDays(fulfillmentDate, 1) : fulfillmentDate
    return dateString(date)
  }

  const fixFulfillmentTime = () => {
    if (fulfillmentTime === 'immediately' || !fulfillmentTime) return null
    return timeString(fulfillmentTime)
  }

  const cartParamsValid = (): boolean => {
    const params = createSlerpCartParams()
    const { fulfillmentDate, address, storeId, merchantId } = params
    const addressValid = fulfillmentType === 'delivery' ? !!address : true

    return !!(
      storeId &&
      merchantId &&
      fulfillmentDate &&
      addressValid &&
      !!validStores.length
    )
  }

  const consumerCartParamsValid = (): boolean => {
    const params = createConsumerCartParams()
    const { fulfillmentDate, storeId, deliveryAddress } = params || {}
    const addressValid =
      fulfillmentType === 'DELIVERY' ? !!deliveryAddress : true

    return !!(
      storeId &&
      fulfillmentTime &&
      fulfillmentDate &&
      addressValid &&
      !!validStores.length
    )
  }

  /**
   * Handles functionality of the submit button
   */
  const handleUserSubmit = (): void | Promise<void> => {
    // If no cartId exists create new cart using consumer API
    if (!cartSession?.cartId) {
      if (!consumerCartParamsValid()) {
        return
      }
      // if no cartId then update using hasura API
    } else {
      if (!cartParamsValid()) {
        return
      }
    }

    // Offer user to save their address if they have no default saved addresses
    if (
      isLoggedIn &&
      currentLocation &&
      fulfillmentType === 'delivery' &&
      !useCustomerDefaultAddress &&
      validateAddressDataForSaving(currentLocation)
    ) {
      setShowSaveAddressModal(true)
    } else {
      // updateSlerpCart when cartId exists
      if (cartSession?.cartId) {
        return handleEditCart()
      } else {
        return handleInitCart()
      }
    }
  }

  /** Call updateSlerpCart with existing cart parameters  */
  const handleEditCart = async (priceChangeAgreed?: boolean) => {
    if (!currentStore) return
    const {
      merchantId,
      metadata,
      address,
      orderItems,
      ...extractedSlerpCartParams
    } = createSlerpCartParams()
    const { cartId, cartSession } = cart

    const cartItems = cartSession?.cart?.cart_items

    const transformedOrderItems =
      (cartItems && transformOrderItems(cartItems)) || []

    const updateSlerpCartParams = cartId && {
      cartId,
      orderItems: transformedOrderItems,
      ...(priceChangeAgreed && { proceedWithNewPrices: true }),
      ...extractedSlerpCartParams
    }

    if (cartId && updateSlerpCartParams) {
      await cart
        .updateCart(updateSlerpCartParams)
        .then((cart: any) => {
          // onClose callback handles cleanup on user submission of modal form
          if (props.onClose) {
            props.onClose()
          }
          history.push(getStoreUrl(currentStore.slug))

          // Ensure latest fulfillment data is shown on delivery summary
          fetchSlerpCart(cart).then((slerpCartResult) => {
            if (slerpCartResult) {
              setSlerpCart(slerpCartResult)
            }
          })

          if (isOverlayOpen) {
            setIsOverlayOpen(false)
            setTimeout(() => setHasCartProductModalError(false), fadeInOutMs)
          }
        })
        .catch((err: ApiError) => {
          const currentInvalidItems: CartItem[] | undefined =
            cartItems && returnInvalidCartItems(err, cartItems)

          if (currentInvalidItems?.length) {
            setInvalidItems(currentInvalidItems)
            setIsOverlayOpen(true)
            if (err.graphQLErrors[0].error === 'SLERP_PRICE_CHANGED_ERROR') {
              setCartProductModalErrorType('PriceChange')
            } else {
              setCartProductModalErrorType('InvalidItems')
            }
            setTimeout(() => {
              setHasCartProductModalError(true)
            }, fadeInOutMs)
          }
        })
    }
  }

  /** Create new cart via consumer API & handles response */
  const handleInitCart = async () => {
    if (!currentStore) return
    const params = createConsumerCartParams()
    if (params) {
      initConsumerCart({ variables: params }).then((res) => {
        if (!res || !res.cart) {
          return
        }

        const { id: cartId } = res.cart || {}
        loadCart()

        if (props.onClose) {
          props.onClose()
        }
        const storeSlug = currentStore?.slug
        // push to the new store location
        history.push(getStoreUrl(currentStore.slug))
        if (inModal && addToCart) {
          addToCart({ cartId, fulfillmentType, storeSlug })
        }
      })
    }
  }

  /** Callback to handle removal of products with invalid / out of stock / price change items on updateSLerpCart */
  const onContinue = () => {
    if (cartProductModalErrorType === 'PriceChange') {
      handleEditCart(true)
      return
    }

    const { cartSession } = cart
    const cartItems = cartSession?.cart?.cart_items
    const filteredCartItems =
      (cartItems &&
        cartItems.filter(
          (item) =>
            !invalidItems.find(
              (invalidItem) =>
                invalidItem.product_variant_id === item.product_variant_id
            )
        )) ||
      []

    if (cartSession?.cart?.cart_items) {
      cartSession.cart.cart_items = filteredCartItems
      handleEditCart()
    }
  }

  const renderContinueButtons = (): JSX.Element => {
    return (
      <>
        <ViewMenuButton
          disabled={
            !cartSession?.cartId
              ? !consumerCartParamsValid()
              : !cartParamsValid()
          }
          onClick={handleUserSubmit}
          testId={'continueButton'}
        >
          View Menu
        </ViewMenuButton>
      </>
    )
  }

  const pickupEnabled = isFulfillmentTypeEnabled(stores, 'pickup')

  // Show fulfillment options even if only one of delivery or pickup is enabled
  const showFulfillmentOptions = deliveryEnabled && pickupEnabled

  useEffect(() => {
    setShowFulfillmentOptions(!!showFulfillmentOptions)
  }, [showFulfillmentOptions, setShowFulfillmentOptions])

  const { isModalOpen, openModal, loginModal } = useModal()

  const handleOpenLoginModal = () => {
    setIsOverlayOpen(!isOverlayOpen)
    setIsLoginOpen(true)
    openModal('login')
  }

  const handleCloseLoginModal = () => {
    setIsOverlayOpen(!isOverlayOpen)
    setTimeout(() => setIsLoginOpen(false), 225)
    closeModal('login')

    // Ensure scrolllock is disabled when comnig back into the fulfillment modal from the login modal
    document.body.style.overflow = 'hidden'
  }

  const currentLocationRef: MutableRefObject<CurrentLocationState | undefined> =
    useRef(undefined)

  const handleSwitchFulfillment = (fulfillmentType: 'pickup' | 'delivery') => {
    if (datesOrStoresLoading) return
    setIsSwitchingFulfillment(true)
    // If user switches back to delivery, set currentLocation/address to ref value
    if (fulfillmentType === 'delivery') {
      if (!pickup_address_enabled && currentLocationRef.current) {
        setCurrentLocation(currentLocationRef.current)
        setAddress(currentLocationRef.current.address)
      }
      SwitchType('delivery')
      setFulfillmentType('delivery')
    } else {
      // save address in ref if user switches back to pickup
      if (!pickup_address_enabled) {
        currentLocationRef.current = currentLocation
      }
      SwitchType('pickup')
      setFulfillmentType('pickup')
    }
    const uppercaseFulfillmentType =
      fulfillmentType.toUpperCase() as FulfillmentType
    trackFulfillmentTypeChange(uppercaseFulfillmentType, 'modal')
    setTimeout(() => setIsSwitchingFulfillment(false), 200)
  }

  return (
    <>
      {isModalOpen('login') &&
        loginModal({
          merchantId: merchant.id,
          onClose: handleCloseLoginModal,
          inFulfillmentModal: true,
          isOverlay: isOverlayOpen,
          isOpen: isLoginOpen,
          inModal: true
        })}
      {hasCartProductModalError && (
        <CartProductErrorModal
          onClose={() => {
            setIsOverlayOpen(false)
            setTimeout(() => setHasCartProductModalError(false), fadeInOutMs)
          }}
          invalidItems={invalidItems}
          onContinue={onContinue}
          storeName={currentStore?.name}
          isOpen={isOverlayOpen}
          type={cartProductModalErrorType}
        />
      )}
      {showSaveAddressModal && currentLocation && (
        <SaveAddressModal
          handleInitCart={cartSession?.cartId ? handleEditCart : handleInitCart}
          setShowSaveAddressModal={setShowSaveAddressModal}
          currentLocation={currentLocation}
        />
      )}

      <FormContainer>
        {showFulfillmentOptions && (
          <FulfillmentSelectContainer data-testid='fulfillment-select-container'>
            <SelectablePillNew
              options={[
                {
                  title: 'Delivery',
                  selected: Fulfillment.type === 'delivery',
                  type: 'delivery',
                  onClick: () => {
                    handleSwitchFulfillment('delivery')
                  }
                },
                {
                  title: 'Collection',
                  selected: Fulfillment.type === 'pickup',
                  type: 'pickup',
                  onClick: () => {
                    handleSwitchFulfillment('pickup')
                  }
                }
              ]}
            />
          </FulfillmentSelectContainer>
        )}
        <FormContentContainer {...{ isSwitchingFulfillment }}>
          {Fulfillment.Form}
        </FormContentContainer>
      </FormContainer>
      <ButtonsContainer>
        <ButtonContainer>{renderContinueButtons()}</ButtonContainer>
      </ButtonsContainer>
      {!isLoggedIn && (
        <DeliverySignInCTAContainer onClick={handleOpenLoginModal}>
          <DeliverySignIn />
        </DeliverySignInCTAContainer>
      )}
    </>
  )
}

const DeliverySignInCTAContainer = styled.div<StyledHTMLElement>(() => ({
  margin: '0 auto 32px'
}))

const ViewMenuButton = styled(Button)(() => ({
  marginBottom: '0'
}))

const FormContainer = styled.div<
  StyledHTMLElement & { showFulfillmentOptions?: boolean },
  Required<Theme>
>(({ theme }) => ({
  padding: `0 0 ${theme.space[5]}px`,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',

  [theme.mediaQueries.viewport7]: {
    overflow: 'visible',
    width: '100%',
    transform: 'none',
    paddingTop: 0,
    ':before': {
      display: 'none'
    }
  }
}))

const FulfillmentSelectContainer = styled.div<
  StyledHTMLElement,
  Required<Theme>
>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '20px',
  paddingBottom: '8px',
  [theme.mediaQueries.viewport7]: {
    gap: '8px',
    justifyContent: 'left'
  }
}))

const ButtonsContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    marginBottom: `${theme.space[4]}px`,
    '&:button': {
      color: theme.colors['primary'],
      backgroundColor: 'white',
      border: `1px solid ${theme.colors['primary']}`
    }
  })
)

const FormContentContainer = styled.div<
  StyledHTMLElement & { isSwitchingFulfillment: boolean },
  Required<Theme>
>(({ theme, isSwitchingFulfillment = false }) => ({
  opacity: isSwitchingFulfillment ? 0 : 1,
  transition: !isSwitchingFulfillment ? 'opacity ease-out 200ms' : ''
}))

const ButtonContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    position: 'sticky',
    width: `calc(100% + ${theme.space[3] * 2}px)`,
    transform: `translateX(-${theme.space[3]}px)`,
    bottom: 0,
    padding: ` 0 ${theme.space[3]}px ${theme.space[3]}px`,
    backgroundColor: 'white',
    zIndex: theme.zIndex.stickyHeader
  })
)

export const RequiredTag = styled.p<
  StyledHTMLElement & { isValid?: boolean; hasFailed?: boolean },
  Required<Theme>
>(({ theme, isValid = false, hasFailed = false }) => ({
  fontSize: '14px',
  display: 'inline-flex',
  textTransform: 'lowercase',
  fontStyle: 'italic',
  fontWeight: 300,
  color: isValid
    ? theme.colors['state']['success']
    : hasFailed
      ? theme.colors['state']['failure']
      : 'black'
}))

export default FulfillmentOptionsNew
