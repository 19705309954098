import { useState } from 'react'
import { Button } from '../Controls'
import FormInput from '../Inputs/FormInput'
import { Container, Content, Subheading } from './commonStyles'
import { StyledHTMLElement } from 'shop/theme/types'
import styled from '@emotion/styled'
import { useHistory, useParams } from 'react-router-dom'
import { ReactComponent as TickIcon } from 'shop/assets/icons/tick.svg'
import { iconDimensions } from '../Shop/commonStyles'
import { useMutation } from '@apollo/client'
import { MUTATE_UPDATE_PASSWORD } from 'shop/client'
import { usePageViewTracker } from 'tracker'
import { getPasswordSubtextColor } from '../Account/utils'
import { useModal } from 'shop/hooks'
import { usePassword } from 'shop/hooks/usePassword'
import { PasswordErrors } from '../Account/types'

export const ResetPassword = () => {
  const [password, setPassword] = useState<string>('')
  const [submitted, setSubmitted] = useState<boolean>(false)
  const { token } = useParams<{ token: string }>()
  const history = useHistory()
  const { openModal } = useModal()
  const { passwordError: passwordError } = usePassword(password)

  usePageViewTracker()

  const [resetPassword] = useMutation(MUTATE_UPDATE_PASSWORD, {
    context: { clientName: 'consumerApi' }
  })

  const onSubmit = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault()
    resetPassword({
      variables: { password, passwordConfirmation: password, token }
    })
      .then(() => {
        setSubmitted(true)
      })
      .catch(() => {})
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const handleNavigateToLogin = () => {
    openModal('login')
    history.push('/')
  }

  return (
    <Content>
      {submitted ? (
        <>
          <h2>Password Changed</h2>
          <Subheading>Your password has been successfully changed</Subheading>
          <Container>
            <StyledTickIcon />
            <Button onClick={handleNavigateToLogin}>Log in</Button>
          </Container>
        </>
      ) : (
        <>
          <h2>Reset Password</h2>
          <Subheading>Please enter your new password</Subheading>
          <form onSubmit={onSubmit}>
            <Container>
              <div>
                <FormInput
                  type='password'
                  showError={false}
                  value={password}
                  labelText={'New Password'}
                  onChange={onChange}
                />
                <InputSubtext passwordError={passwordError}>
                  Password requires at least 8 characters
                </InputSubtext>
              </div>
              <Button
                data-testid='continue'
                type='submit'
                disabled={!!passwordError}
              >
                Update
              </Button>
            </Container>
          </form>
        </>
      )}
    </Content>
  )
}

const InputSubtext = styled.p<
  StyledHTMLElement & { passwordError: PasswordErrors }
>(({ passwordError }) => ({
  fontSize: '14px',
  color: getPasswordSubtextColor(passwordError),
  fontWeight: 500,
  margin: '8px 0 0'
}))

const StyledTickIcon = styled(TickIcon)(() => ({
  ...iconDimensions,
  margin: '0 auto',
  '> path': {
    fill: '#389E0D'
  }
}))
