import { RouteComponentProps } from 'react-router-dom'
import { AppContentLoader, LoaderSwitcher } from 'router/loaders'
import { Checkout } from '../../shop/pages'
import { CheckoutContextProvider } from 'shop/hooks'
import { ConsumerCartLoader } from 'router/loaders/ConsumerCartLoader'

export const CheckoutWrapper = (props: RouteComponentProps) => {
  return (
    <AppContentLoader>
      {(isParentLoaded) => (
        <ConsumerCartLoader isParentLoaded={isParentLoaded}>
          {(isParentLoaded) => (
            <CheckoutContextProvider>
              <LoaderSwitcher isParentLoaded={isParentLoaded}>
                <Checkout />
              </LoaderSwitcher>
            </CheckoutContextProvider>
          )}
        </ConsumerCartLoader>
      )}
    </AppContentLoader>
  )
}
