import { useShop, useCart, useAppContent } from 'shop/hooks'
import Logo from './Logo'
import { Nav } from './NavBar'
import { getLogoURL } from 'shop/utils/common'

import styled from '@emotion/styled'

const MobileNavBar = ({
  leftAction,
  rightAction
}: {
  leftAction?: React.ReactElement
  rightAction?: React.ReactElement
}) => {
  const { config } = useShop()
  const cartManager = useCart()
  const cart = cartManager.cartSession.cart
    ? cartManager.cartSession.cart
    : null

  const { logoImage } = useAppContent()
  const logoUrl = logoImage || getLogoURL(config.domain, config.assetHost)

  const tableEnabled =
    cart && cart.metadata && cart.metadata.pickup_type === 'table'

  const storeSlug = cart && cart.store && cart.store.slug
  const tableLandingPath = `/table/${storeSlug}`
  const storePath = `store/${storeSlug}` || '/'

  return (
    <Container data-testid='mobile-nav-bar-component' isSearchActive={false}>
      <LeftActions>{leftAction && leftAction}</LeftActions>
      <Logo
        imageUrl={logoUrl}
        url={tableEnabled ? tableLandingPath : storePath}
      />
      <RightActions>{rightAction && rightAction}</RightActions>
    </Container>
  )
}

const LeftActions = styled.div(() => ({
  display: 'flex',
  width: '36px'
}))

const RightActions = styled.div(() => ({
  textAlign: 'right',
  width: '36px'
}))

const Container = styled(Nav)(() => ({
  padding: '8px',
  flexDirection: 'row'
}))

export default MobileNavBar
