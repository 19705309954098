import { ChangeEvent, useState } from 'react'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import FormInput from '../Inputs/FormInput'
import { useMediaQueries } from 'shop/hooks'
import { findMarginTopForCenter } from './utils'

type Props = {
  setTipAmount: (value: string) => void
  currentTipValue: string | null
}

const CustomTip = ({ currentTipValue, setTipAmount }: Props) => {
  const { isMobile } = useMediaQueries()
  const [tipInputString, setTipInputString] = useState<string>(
    currentTipValue || ''
  )
  const [errorMessage, setErrorMessage] = useState<string>('')

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('')
    const inputValue = e.currentTarget.value
    setTipInputString(inputValue)
    if (isNaN(Number(inputValue)) || Number(inputValue) < 0) {
      setTipAmount('0.00')
      setErrorMessage('please enter a positive numeric value')
      return
    }
    setTipAmount(inputValue)
  }

  const handleBlurConvertToCurrency = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (isNaN(Number(tipInputString)) || Number(tipInputString) < 0) {
      setTipAmount('0.00')
      setErrorMessage('please enter a positive numeric value')
      return
    }
    if (Number(tipInputString) === 0) {
      setTipAmount('0.00')
      return
    }
    const convertedToCurrencyStirng = Number(tipInputString)?.toFixed(2)
    setTipInputString(convertedToCurrencyStirng)
    setTipAmount(convertedToCurrencyStirng)
    event.target.value = `£${convertedToCurrencyStirng}`
  }

  const handleFocus = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTipInputString('')
    event.target.value = ''
    setTipAmount('0.00')
    if (isMobile) {
      const tippingModalRef = document.getElementById('overlay')
      if (tippingModalRef) {
        tippingModalRef.style.margin = '20px auto'
      }
    }
  }

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleBlurConvertToCurrency(event)
    if (isMobile) {
      const tippingModalRef = document.getElementById('overlay')
      if (tippingModalRef) {
        setTimeout(() => {
          tippingModalRef.style.margin = `${findMarginTopForCenter()}px auto`
        }, 100)
      }
    }
  }

  return (
    <CustomTipContainer>
      <FormInput
        id='custom-tip'
        data-testid='custom-tip-input'
        labelText='Enter another amount'
        type='text'
        inputMode='numeric'
        onChange={handleInput}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={tipInputString}
        errorMessage={errorMessage}
        showError={!!errorMessage}
        autoComplete='off'
      />
    </CustomTipContainer>
  )
}

const CustomTipContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexGrow: 1,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',

    '& h2': {
      fontSize: '14px',
      fontWeight: 'normal',
      color: theme.colors['textMute']
    }
  })
)

export default CustomTip
