import styled from '@emotion/styled'
import { CartV2 } from 'shop/components/CartV2'
import { Theme } from 'styled-system'
import { StyledHTMLElement } from 'shop/theme/types'
import { Order } from 'shop/types/cart'
import Spinner from 'shop/components/Loader/Spinner'
import { SpinnerContainer } from '../commonStyles'

interface Props {
  order?: Order
}

const OrderSummary = ({ order }: Props) => {
  // handle loading state
  if (!order) {
    return (
      <Content data-testid='order-summary'>
        <SpinnerContainer>
          <Spinner size='40px' />
        </SpinnerContainer>
      </Content>
    )
  }

  const {
    orderItems,
    summary,
    orderNotes,
    additionalItems,
    reference,
    fulfillment
  } = order

  const fulfillmentType = fulfillment.type

  return (
    <Content data-testid='order-summary'>
      <CartV2
        type={'OrderSummary'}
        orderItems={orderItems}
        summary={summary}
        orderNotes={orderNotes}
        fulfillmentType={fulfillmentType}
        additionalItems={additionalItems}
        reference={reference}
      />
    </Content>
  )
}

export const Content = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    padding: '16px 16px 0',
    backgroundColor: '#fff',
    maxWidth: '99vw',
    width: '100%',
    minWidth: 0,

    [theme?.mediaQueries?.viewport5]: {
      borderRadius: '12px',
      padding: '32px 32px 0'
    },

    [theme?.mediaQueries?.viewport7]: {
      maxWidth: '674px',
      padding: '20px 32px 12px',
      marginBottom: '24px'
    }
  })
)

export default OrderSummary
