import { useEffect, useRef } from 'react'

type IntersectionObserverCallback = (
  entries: IntersectionObserverEntry[]
) => void

/** Custom hook to create & manage an IntersectionObserver */
export const useIntersectionObserver = (
  callback: IntersectionObserverCallback,
  target: Element | null,
  options: IntersectionObserverInit
) => {
  const observerRef = useRef<IntersectionObserver | null>(null)

  useEffect(() => {
    // Disconnect the existing observer if it exists
    if (observerRef.current) {
      observerRef.current.disconnect()
    }

    // Create a new observer if the target is provided
    if (target) {
      observerRef.current = new IntersectionObserver(callback, options)
      observerRef.current.observe(target)
    }

    // Cleanup function to disconnect the observer when the component unmounts or dependencies change
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
    }
  }, [callback, target, options])

  // Return the current observer instance
  return observerRef.current
}

export default useIntersectionObserver
