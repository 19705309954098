import styled from '@emotion/styled'
import { StyledHTMLElement } from 'shop/theme/types'
import {
  ASAP_ORDER,
  FulfillmentWindow,
  OrderType,
  PREORDER_ORDER
} from 'shop/types'
import { Title } from '../../common'
import { CartBreakdownItem, NoteBreakdownItem } from '../index'
import {
  IoLocationOutline as PinIcon,
  IoBicycleOutline as BikeIcon,
  IoReceiptOutline as ReceiptIcon,
  IoTimeOutline as ClockIcon,
  IoHomeOutline as HomeIcon,
  IoInformationOutline as InfoIcon
} from 'react-icons/io5'
import { format } from 'date-fns'
import { parseTimezoneISO } from 'shop/utils'
import { Medium, Semibold } from 'shop/components/common'
import { BaseAddress, Fulfillment } from 'shop/types/cart'
import { createAddressString } from 'shop/utils/address'
import _ from 'lodash'
import { getFulfillmentFlags } from 'shop/components/Checkout'
import { formatFulfillmentWindow } from 'shop/utils/common'

interface Props {
  fulfillment: Fulfillment
  storeName: string
  merchantName: string | null
  deliveryAddress: BaseAddress | null
  storeAddress: BaseAddress
  dropoffNotes: string | null
  hideAddressSection?: boolean
  onChangeButtonClick?: () => void
}

const FulfillmentDetails = ({
  fulfillment,
  storeName,
  merchantName,
  deliveryAddress,
  storeAddress,
  dropoffNotes,
  hideAddressSection = false,
  onChangeButtonClick
}: Props) => {
  const { orderType, type: fulfillmentType, window, tableNumber } = fulfillment

  const { isPickup, isOrderAtTable, isDelivery } =
    getFulfillmentFlags(fulfillmentType)

  if (isPickup && (!merchantName || !storeName || _.isEmpty(storeAddress))) {
    return <></>
  }

  const fulfillmentDetails = {
    DELIVERY: {
      timeIcon: BikeIcon,
      addressIcon: HomeIcon,
      title: 'Delivery Details',
      prefix: 'Deliver to ',
      text:
        (deliveryAddress &&
          createAddressString(deliveryAddress, { withCommas: true })) ||
        ''
    },
    PICKUP: {
      timeIcon: ClockIcon,
      addressIcon: PinIcon,
      title: 'Pickup Details',
      prefix: 'Pickup from ',
      text: `${merchantName} ${storeName}, ${createAddressString(storeAddress, {
        withCommas: true
      })}`
    },
    ORDER_AT_TABLE: {
      timeIcon: undefined,
      addressIcon: ReceiptIcon,
      title: 'Table Order Details',
      prefix: 'Table ',
      text: tableNumber || ''
    }
  }

  const createFulfillmentInfo = (
    orderType: OrderType,
    window: FulfillmentWindow
  ) => {
    const dayOfWeek = (date: string) => format(parseTimezoneISO(date), 'EEEE')
    const formattedDate = (date: string) =>
      format(parseTimezoneISO(date), 'dd/MM/yy')
    const isAsap = orderType === ASAP_ORDER
    const isPreorder = orderType === PREORDER_ORDER
    const asapText = isAsap ? ' (ASAP)' : ''
    const todayOrDate = isPreorder
      ? `${dayOfWeek(window.to)} ${formattedDate(window.to)}`
      : 'Today'
    const arrivalOrPickup = isDelivery ? 'Estimated arrival' : 'Pickup time'

    if (isDelivery || isPickup) {
      return (
        <>
          {arrivalOrPickup} <Medium>{todayOrDate}</Medium> at{' '}
          <Medium>
            {formatFulfillmentWindow(window)}
            {asapText}
          </Medium>
        </>
      )
    }

    return null
  }

  return (
    <div>
      <Title>{fulfillmentDetails[fulfillmentType].title}</Title>
      {!isOrderAtTable && (
        <CartBreakdownItem
          LeftIcon={fulfillmentDetails[fulfillmentType].timeIcon}
          label={createFulfillmentInfo(orderType, window)}
          padding={'16px 0 8px 0'}
          withEllipsis={false}
          withLineBreak={false}
          maxWidth={'90%'}
        />
      )}
      {!hideAddressSection && (
        <CartBreakdownItem
          label={
            <>
              {fulfillmentDetails[fulfillmentType].prefix}
              <Semibold>{fulfillmentDetails[fulfillmentType].text}</Semibold>
            </>
          }
          maxWidth={'100%'}
          withEllipsis={false}
          withLineBreak={!isOrderAtTable}
          LeftIcon={fulfillmentDetails[fulfillmentType].addressIcon}
          padding={'8px 0 8px 0'}
          {...(onChangeButtonClick && { onChangeButtonClick })}
        />
      )}
      {isDelivery && !hideAddressSection && !!dropoffNotes && (
        <DeliveryInstructionsContainer>
          <NoteBreakdownItem
            Icon={InfoIcon}
            noteTitle={'Delivery Instructions'}
            note={dropoffNotes}
          />
        </DeliveryInstructionsContainer>
      )}
    </div>
  )
}

export const Label = styled.p<StyledHTMLElement>(() => ({
  margin: 0
}))

const DeliveryInstructionsContainer = styled.div<StyledHTMLElement>(() => ({
  borderRadius: '12px',
  marginBottom: '12px'
}))

export default FulfillmentDetails
