import React, { useCallback, useEffect, useState } from 'react'

import { useAppContent, useLoader, useShop } from 'shop/hooks'
import { Partner } from 'shop/components/Landing'
import { LoaderProps } from './types'
import { AppContentQueryResponse } from 'shop/hooks/useGlobalContext/types'

export const AppContentLoader = ({
  isParentLoaded = true,
  children
}: LoaderProps) => {
  const { fetchAppContent } = useLoader()
  const { setAppContent } = useAppContent()
  const { partner, isPartnerLoading } = useShop()

  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const fetchData = useCallback(async () => {
    const appContentResult = await fetchAppContent()

    if (appContentResult) {
      setAppContent(appContentResult)
    }

    setIsLoaded(Boolean(appContentResult) && isParentLoaded)
  }, [fetchAppContent, setAppContent, isParentLoaded])

  const mapPartnerToAppContent = (
    partner: Partner
  ): AppContentQueryResponse => {
    let tempAppContent: AppContentQueryResponse = {
      merchantName: partner.name,
      promotionalBanner: partner.content.promotionalBanner,
      logoImage: partner.content.images.logos.primary?.original || '',
      altLogoImage: partner.content.images.logos.alternative?.original || '',
      splashImage: partner.content.images.splash?.original || '',
      shop: {
        header: {
          bannerImage: partner.content.images.banner?.original || ''
        }
      }
    }
    return tempAppContent
  }

  useEffect(() => {
    if (isPartnerLoading || !isParentLoaded) return
    if (!partner) {
      fetchData()
      return
    }
    const mappedAppContent = mapPartnerToAppContent(partner)
    setAppContent(mappedAppContent)
    setIsLoaded(!!isParentLoaded)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParentLoaded, partner, isPartnerLoading])

  return <React.Fragment>{children(isLoaded)}</React.Fragment>
}
