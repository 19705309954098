import styled from '@emotion/styled'
import IconTooltip from 'shop/components/Tooltip/Tooltip'
import ContentLoader from 'react-content-loader'
import { IconType } from 'react-icons'
import { Label } from '../commonStyles'
import { StyledHTMLElement } from 'shop/theme/types'
import { BasePrice, Price as PriceType } from 'shop/types/cart'
import { Price } from '../Price'
import { LineBreak } from 'shop/components/Shop/commonStyles'

interface Props {
  RightIcon?: IconType
  LeftIcon?: IconType
  withTooltip?: boolean
  withEllipsis?: boolean
  toolTipMessage?: string
  fontWeight?: number
  padding?: string
  fontSize?: number
  maxWidth?: string
  label: string | React.ReactNode
  price?: PriceType | BasePrice | null
  pricePlaceholder?: string
  isCartLoading?: boolean
  children?: React.ReactNode
  withLineBreak?: boolean
  lineBreakHeight?: string
  onChangeButtonClick?: () => void
  changeButtonText?: string
}

const CartBreakdownItem = ({
  fontWeight = 400,
  fontSize = 16,
  maxWidth = '70%',
  label,
  price,
  pricePlaceholder,
  padding = '',
  withEllipsis = true,
  withTooltip,
  toolTipMessage,
  LeftIcon,
  RightIcon,
  children,
  withLineBreak = true,
  onChangeButtonClick,
  isCartLoading = false,
  lineBreakHeight = '1px',
  changeButtonText = 'Change'
}: Props) => {
  return (
    <div data-testid='cart-breakdown-item'>
      {withLineBreak && (
        <LineBreak height={lineBreakHeight} data-testid='cart-breakdown-line' />
      )}
      <Content
        data-testid='cart-breakdown-content'
        fontSize={fontSize}
        fontWeight={fontWeight}
        hasChildren={!!children}
        maxWidth={maxWidth}
        padding={padding}
      >
        <>
          <Item>
            {!!LeftIcon && <LeftIcon data-testid='left-icon' />}
            <Label withEllipsis={withEllipsis}>
              <>{label}</>
            </Label>
            {!!withTooltip && toolTipMessage && (
              <IconTooltip tooltipMessage={toolTipMessage} />
            )}
            {!!RightIcon && <RightIcon data-testid='right-icon' />}
            {!!onChangeButtonClick && !price && !isCartLoading && (
              <ChangeText
                data-testid='change-button'
                onClick={onChangeButtonClick}
              >
                {changeButtonText}
              </ChangeText>
            )}
          </Item>
        </>
        {isCartLoading && (
          <ContentLoader
            data-testid='cart-breakdown-price-loader'
            speed={2}
            style={{
              width: '55px',
              height: '18px',
              borderRadius: '5px',
              alignSelf: 'center'
            }}
          />
        )}
        {!isCartLoading && !!price && (
          <PriceContainer data-testid='cart-breakdown-price'>
            {!!onChangeButtonClick && (
              <ChangeText
                data-testid='change-button'
                onClick={onChangeButtonClick}
              >
                {changeButtonText}
              </ChangeText>
            )}
            <Price price={price} />
          </PriceContainer>
        )}
        {!isCartLoading && !price && pricePlaceholder && (
          <PriceContainer data-testid='cart-breakdown-price-placeholder'>
            <PricePlaceholder> {pricePlaceholder} </PricePlaceholder>
          </PriceContainer>
        )}
      </Content>
      {!!children && children}
    </div>
  )
}

const Content = styled.div<
  StyledHTMLElement & {
    fontWeight: number
    fontSize: number
    hasChildren: boolean
    maxWidth: string
    padding: string
  }
>(({ fontWeight, fontSize, hasChildren, maxWidth, padding }) => ({
  '> div': {
    maxWidth: maxWidth
  },
  fontWeight: fontWeight,
  padding: !!padding ? padding : hasChildren ? '8px 0 0' : '8px 0',
  fontSize: fontSize + 'px',
  display: 'flex',
  justifyContent: 'space-between'
}))

const Item = styled.div(() => ({
  alignItems: 'center',
  display: 'flex',
  flex: 'auto',
  '> svg': {
    marginRight: '8px',
    width: '20px',
    height: '20px',
    flexShrink: 0
  },
  'div > svg': {
    marginLeft: '4px',
    width: '12px',
    height: '12px',
    fill: '#59595A',
    flexShrink: 0
  }
}))

const PriceContainer = styled.div(() => ({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
  position: 'relative'
}))

const ChangeText = styled.p<StyledHTMLElement>(() => ({
  textDecoration: 'underline',
  fontWeight: 500,
  fontSize: '14px',
  marginLeft: 'auto',
  paddingLeft: '8px',
  cursor: 'pointer'
}))

const PricePlaceholder = styled.p(() => ({
  fontWeight: 400,
  fontStyle: 'italic'
}))

export default CartBreakdownItem
