import React, {
  useEffect,
  useReducer,
  useContext,
  useState,
  useMemo
} from 'react'
import { useConsumerCart, useReactRouter } from 'shop/hooks'
import styled from '@emotion/styled'
import { FulfillmentProps } from './types'
import PickupForm from './Pickup/PickupForm'
import DeliveryForm from './Delivery/DeliveryForm'
import { LandingContext } from './LandingContext'
import { Button } from 'shop/components'
import {
  hasValidStore,
  dateString,
  trackFulfillmentTypeChange
} from 'shop/components/Landing/utils'
import { validateAddressDataForSaving } from './addressUtils'
import { getStoreUrl } from 'shop/utils/store'
import SaveAddressModal from './SaveAddressModal'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import SelectablePill from './SelectablePill'
import { addDays } from 'date-fns'
import { FulfillmentType } from 'shop/types'
import { BaseAddress, ConsumerCartPayload } from 'shop/types/cart'
import { constructDeliveryAddress, fixFulfillmentTime } from 'shop/utils'

const FulfillmentOptions = (
  props: FulfillmentProps & { label: React.ReactNode; hasPadding?: boolean }
) => {
  const landingContext = useContext(LandingContext)
  const { history } = useReactRouter()
  const { stores, label, hasPadding } = props
  const customerId = localStorage.getItem('customerId')
  const isLoggedIn = customerId !== null
  const [showSaveAddressModal, setShowSaveAddressModal] =
    useState<boolean>(false)

  const {
    fulfillmentDate,
    fulfillmentType,
    setFulfillmentType,
    fulfillmentTime,
    currentLocation,
    currentStore,
    useCustomerDefaultAddress,
    isExtendedHour,
    setHasFailedSubmit,
    setShowFulfillmentOptions,
    validStores,
    loadingDates,
    loadingStores
  } = landingContext

  const { initConsumerCart } = useConsumerCart()

  const deliveryEnabled = hasValidStore(stores, 'delivery')
  const pickupEnabled = hasValidStore(stores, 'pickup')

  const datesOrStoresLoading = useMemo(
    () => loadingDates[fulfillmentType] || loadingStores[fulfillmentType],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingDates[fulfillmentType], loadingStores[fulfillmentType]]
  )

  useEffect(() => {
    const defaultFulfillment = deliveryEnabled ? 'delivery' : 'pickup'
    setFulfillmentType(defaultFulfillment)
    SwitchType(defaultFulfillment)
  }, [deliveryEnabled, setFulfillmentType])

  const FulfillmentSetting = {
    Form: <></>,
    onReady: () => {},
    type: ''
  }

  const selectFulfillment = (state: any, type: any) => {
    switch (type) {
      case 'delivery':
        return {
          type: 'delivery',
          Form: <DeliveryForm {...props} />
        }
      case 'pickup':
        return {
          type: 'pickup',
          Form: <PickupForm {...props} />
        }
      default:
        return {
          type: '',
          Form: <></>
        }
    }
  }

  const [Fulfillment, SwitchType] = useReducer(
    selectFulfillment,
    FulfillmentSetting
  )

  const fixFulfillmentDate = () => {
    const date = isExtendedHour ? addDays(fulfillmentDate, 1) : fulfillmentDate
    return dateString(date)
  }

  const cartParamsValid = (): boolean => {
    const params = createConsumerCartParams()
    if (!params) return false

    const { fulfillmentDate, deliveryAddress, storeId } = params
    const addressValid =
      fulfillmentType === 'delivery' ? !!deliveryAddress : true

    return !!(
      storeId &&
      fulfillmentTime &&
      fulfillmentDate &&
      addressValid &&
      !!validStores.length
    )
  }

  /**
   * Handles functionality of the submit button
   */
  const handleUserSubmit = (): void | Promise<void> => {
    if (!cartParamsValid()) {
      handleFormValidation()
      return
    }
    // Offer user to save their address if they have no default saved addresses
    if (
      isLoggedIn &&
      currentLocation &&
      fulfillmentType === 'delivery' &&
      !useCustomerDefaultAddress &&
      validateAddressDataForSaving(currentLocation)
    ) {
      setShowSaveAddressModal(true)
    } else {
      return handleInitCart()
    }
  }

  const handleFormValidation = (): void => {
    setHasFailedSubmit(true)
    // Represents the inputs present on the Landing Form, in order.
    const requiredInputs = ['postcode', 'date', 'store', 'time']
    requiredInputs.every((input: string) => {
      const nextRequiredInput = document.getElementById(
        `invalid-required-tag-${input}`
      )
      if (nextRequiredInput) {
        const yOffset = 100
        const newYPos =
          nextRequiredInput.getBoundingClientRect().top +
          window.scrollY -
          yOffset
        window.scrollTo({
          top: newYPos,
          behavior: 'smooth'
        })
        return false
      }
      return true
    })
  }

  const createConsumerCartParams = (): ConsumerCartPayload | null => {
    if (!currentStore) return null
    return {
      storeId: currentStore.id,
      fulfillmentDate: fulfillmentDate && fixFulfillmentDate(),
      fulfillmentTime: fixFulfillmentTime(fulfillmentTime, {
        consumerApi: true
      }),
      fulfillmentType: fulfillmentType.toUpperCase() as FulfillmentType,
      deliveryAddress: constructDeliveryAddress(
        currentLocation,
        fulfillmentType,
        { consumerApi: true }
      ) as BaseAddress
    }
  }

  const handleInitCart = async () => {
    if (!currentStore) return

    const params = createConsumerCartParams()
    if (params) {
      initConsumerCart({ variables: params }).then(() => {
        history.push(getStoreUrl(currentStore.slug))
      })
    }
  }

  const showFulfillmentOptions = Boolean(deliveryEnabled && pickupEnabled)

  useEffect(() => {
    setShowFulfillmentOptions(!!showFulfillmentOptions)
  }, [showFulfillmentOptions, setShowFulfillmentOptions])

  return (
    <>
      {showSaveAddressModal && currentLocation && (
        <SaveAddressModal
          handleInitCart={handleInitCart}
          setShowSaveAddressModal={setShowSaveAddressModal}
          currentLocation={currentLocation}
        />
      )}

      <FormContainer hasTopPadding={hasPadding}>
        {deliveryEnabled && pickupEnabled && label}
        {showFulfillmentOptions && (
          <FulfillmentSelectContainer data-testid='fulfillmentOptions'>
            <SelectablePill
              options={[
                {
                  title: 'Delivery',
                  selected: Fulfillment.type === 'delivery',
                  type: 'delivery',
                  onClick: () => {
                    if (datesOrStoresLoading) return
                    SwitchType('delivery')
                    setFulfillmentType('delivery')
                    trackFulfillmentTypeChange('DELIVERY', 'landing')
                  }
                },
                {
                  title: 'Pickup',
                  selected: Fulfillment.type === 'pickup',
                  type: 'pickup',
                  onClick: () => {
                    if (datesOrStoresLoading) return
                    SwitchType('pickup')
                    setFulfillmentType('pickup')
                    trackFulfillmentTypeChange('PICKUP', 'landing')
                  }
                }
              ]}
            />
          </FulfillmentSelectContainer>
        )}

        {Fulfillment.Form}
      </FormContainer>
      <ButtonContainer>
        <Button
          onClick={handleUserSubmit}
          testId={'continueButton'}
          disabled={!cartParamsValid()}
        >
          Shop now
        </Button>
      </ButtonContainer>
    </>
  )
}

const FormContainer = styled.div<
  StyledHTMLElement & { hasTopPadding?: boolean },
  Required<Theme>
>(({ theme, hasTopPadding = true }) => ({
  // increase width for shallower curve of grey background
  padding: `${hasTopPadding ? theme.space[3] : 0} ${theme.space[3]}px ${theme.space[6] * 2 + 50}px`,
  width: `calc(100% + (${2 * theme.space[3]}px))`,
  transform: `translateX(-${theme.space[3]}px)`,
  position: 'relative',
  overflow: 'hidden',
  flexGrow: 1,

  [theme.mediaQueries.viewport7]: {
    overflow: 'visible',
    width: '100%',
    transform: 'none',
    paddingTop: 0,
    ':before': {
      display: 'none'
    }
  }
}))

const FulfillmentSelectContainer = styled.div<
  StyledHTMLElement,
  Required<Theme>
>(({ theme }) => ({
  display: 'flex',
  marginTop: '16px',
  paddingBottom: '32px',
  justifyContent: 'center',
  gap: '20px',
  [theme.mediaQueries.viewport7]: {
    gap: '8px',
    justifyContent: 'left'
  }
}))

const ButtonContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    position: 'sticky',
    width: `calc(100% + ${theme.space[3] * 2}px)`,
    transform: `translateX(-${theme.space[3]}px)`,
    bottom: 0,
    padding: `${theme.space[4]}px ${theme.space[3]}px`,
    backgroundColor: 'white',
    borderTop: `1px solid ${theme.colors['lineColor']}`,
    zIndex: theme.zIndex.stickyHeader
  })
)

export const RequiredTag = styled.p<
  StyledHTMLElement & { isValid?: boolean; hasFailed?: boolean },
  Required<Theme>
>(({ theme, isValid = false, hasFailed = false }) => ({
  fontSize: '14px',
  display: 'inline-flex',
  textTransform: 'lowercase',
  fontStyle: 'italic',
  fontWeight: 300,
  color: isValid
    ? theme.colors['state']['success']
    : hasFailed
      ? theme.colors['state']['failure']
      : 'black'
}))

export default FulfillmentOptions
