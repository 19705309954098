/**
 * Calculate if the tip value is greater than zero.
 * We do this because the BE can provide us values of null, "0.00" or "1,302.24"
 */
export const isTipMoreThanZero = (
  tipValue: string | number | null
): boolean => {
  if (!tipValue) return false
  if (typeof tipValue === 'number') return tipValue > 0
  return Number(tipValue.replace(/,/g, '')) > 0 // handle large numbers with commas
}

export const findMarginTopForCenter = (): number => {
  const tippingModalRef = document
    .getElementById('overlay')
    ?.getBoundingClientRect()
  if (tippingModalRef) {
    const modalHeight = tippingModalRef.height
    const pageHeight = window.innerHeight
    return (pageHeight - modalHeight) / 2
  }
  return 200
}
