import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'

export const InputLine = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    position: 'relative',
    display: 'flex',
    width: '100%'
  })
)

export const PlaceholderToLabelText = styled.label<
  StyledHTMLElement & {
    isInputFocused: boolean
    hasValue: boolean
    labelOffsetLeft: number
    labelOffsetTop: number
    labelOffsetTopFocused: number
    showError: boolean
  },
  Required<Theme>
>(
  ({
    theme,
    isInputFocused,
    hasValue,
    labelOffsetLeft,
    labelOffsetTop,
    labelOffsetTopFocused,
    showError
  }) => ({
    position: 'absolute',
    left: isInputFocused || hasValue ? '16px' : `${labelOffsetLeft}px`,
    top:
      isInputFocused || hasValue
        ? `${labelOffsetTopFocused}px`
        : `${labelOffsetTop}px`,
    cursor: 'text',
    backgroundColor: isInputFocused || hasValue ? 'white' : '',
    padding: ' 0 2px',
    lineHeight: '18px',
    pointerEvents: 'none',

    fontSize: isInputFocused || hasValue ? '12px' : '16px',
    color: isInputFocused
      ? '#000'
      : showError
        ? theme.colors['state'].error[5]
        : '#8B8B8B',

    transition: 'all ease-in-out 100ms',

    // Ellipsis ensures long placeholders do not overflow/wrap on mobile
    ...(!hasValue &&
      !isInputFocused && {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '70%'
      }),
    [theme.mediaQueries.viewport6]: {
      width: 'auto',
      textOverflow: 'clip',
      whiteSpace: 'normal',
      overflow: 'visible'
    }
  })
)

export const PlaceholderPreText = styled.span<
  StyledHTMLElement,
  Required<Theme>
>(({ theme }) => ({
  fontSize: '16px',
  padding: '13px 0 13px 12px'
}))

export const TextInput = styled.input<
  StyledHTMLElement & {
    hasPlaceholderPreText: boolean
    isPassword: boolean
    showError: boolean
    isInputFocused: boolean
  },
  Required<Theme>
>(
  ({
    theme,
    hasPlaceholderPreText,
    isPassword,
    showError,
    isInputFocused
  }) => ({
    border: '1px solid',
    borderColor: isInputFocused
      ? '#000'
      : showError
        ? theme.colors['state'].error[5]
        : '#8B8B8B',
    borderLeft: hasPlaceholderPreText ? 'none' : '',
    borderRadius: hasPlaceholderPreText ? '0 12px 12px 0' : '12px',
    width: '100%',
    padding: '13px 15px',
    fontSize: '16px',
    paddingRight: isPassword ? '40px' : '15px',
    font: 'inherit',

    ...(isInputFocused && {
      borderColor: '#000',
      borderLeft: hasPlaceholderPreText ? 'none' : '',
      outline: 'none'
    })
  })
)
