import { Cart, CartItem } from 'shop/components/Landing'
import {
  checkMinimumOrderValue,
  getCartMinOrderValue,
  isItemAvailable
} from './../components/Cart/utils'
import keyBy from 'lodash/keyBy'
import { buildAvailableStoreProductsQuery } from 'shop/client'
import { ApolloClient } from '@apollo/client'
import { ShopConfig } from 'shop/types'

// need to check 4 things
// - minimum order value
// - cart item availability
// - cart item validity
// - category availability

type Hook = () => {
  validateCart: (
    cart: Cart,
    cartTotal: number,
    config: ShopConfig,
    client: ApolloClient<object>
  ) => Promise<{
    isCartValid: boolean
    invalidCartItems: CartItem[]
    validCartItems: CartItem[]
    unavailableCartItems: CartItem[]
    minimumOrderValue: number | string
    isMinimumOrderValue: boolean
  }>
}

export const useCartValidation: Hook = () => {
  const validateCart = async (
    cart: Cart,
    cartTotal: number,
    config: ShopConfig,
    client: ApolloClient<object>
  ) => {
    const isPreOrder = !!(cart && cart.deliver_by && cart.is_preorder)
    const minimumOrderValue = getCartMinOrderValue(isPreOrder, cart)

    const isMinimumOrderValue = checkMinimumOrderValue(
      cartTotal,
      minimumOrderValue
    )

    const availabilityQuery = {
      query: buildAvailableStoreProductsQuery(isPreOrder),
      variables: { slug: cart.store.slug }
    }

    const availableProducts = await client
      .query({
        query: availabilityQuery.query,
        variables: availabilityQuery.variables
      })
      .then((result) => result.data.store_variants)

    const storeItems = keyBy(availableProducts, 'variant_id')

    const validCartItems = cart.cart_items.filter((item: CartItem) => {
      return (
        item.is_valid &&
        isItemAvailable(storeItems[item.product_variant_id], item.quantity)
      )
    })

    const invalidCartItems = cart.cart_items.filter(
      (item: CartItem) => !item.is_valid
    )

    const unavailableCartItems = cart.cart_items.filter(
      (item: CartItem) =>
        !isItemAvailable(storeItems[item.product_variant_id], item.quantity)
    )

    const hasInvalidCartItems =
      unavailableCartItems.length > 0 || invalidCartItems.length > 0

    const isCartValid =
      !hasInvalidCartItems && isMinimumOrderValue && cart.cart_items.length > 0

    return {
      isCartValid,
      validCartItems,
      invalidCartItems,
      unavailableCartItems,
      minimumOrderValue,
      isMinimumOrderValue
    }
  }

  return { validateCart }
}
