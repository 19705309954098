import styled from '@emotion/styled'
import Heading from '../../Heading'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { getOrderProgress } from 'shop/components/Order/utils'
import StatusProgress from './StatusProgress'
import DeliveryPin from './DeliveryPin'
import EtaDisplay from './EtaDisplay'
import { createAddressString } from 'shop/utils/address'
import { Order } from 'shop/types/cart'
import { CartBreakdownItem } from 'shop/components/CartV2'
import { Semibold } from 'shop/components/common'
import {
  IoReceiptOutline as ReceiptIcon,
  IoLocationOutline as PinIcon,
  IoHomeOutline as HomeIcon
} from 'react-icons/io5'
import { SpinnerContainer } from '../commonStyles'
import Spinner from 'shop/components/Loader/Spinner'
import { useAppContent, useShop } from 'shop/hooks'
import { DELIVERY_FULFILLMENT } from 'shop/types'
import TrackMap from '../TrackMap'

const Banner = ({ order }: { order: Order | undefined }) => {
  const { merchantName } = useAppContent()
  const { config } = useShop()

  // handle loading state
  if (!order) {
    return (
      <Container>
        <SpinnerContainer>
          <Spinner size='40px' />
        </SpinnerContainer>
      </Container>
    )
  }

  const isDelivery = order.fulfillment.type === DELIVERY_FULFILLMENT
  const { code, message } = getOrderProgress(order, merchantName)

  const deliveryPin = order.delivery?.pincode
  const fulfillmentType = order.fulfillment.type

  const fulfillmentData = {
    DELIVERY: {
      prefix: 'Deliver to ',
      text: createAddressString(order.deliveryAddress, {
        withCommas: true
      }),
      icon: HomeIcon
    },
    PICKUP: {
      prefix: 'Pickup from ',
      text: createAddressString(order.store?.address, {
        withCommas: true
      }),
      icon: PinIcon
    },
    ORDER_AT_TABLE: {
      prefix: 'Table ',
      text: order.fulfillment?.tableNumber || '',
      icon: ReceiptIcon
    }
  }

  const isRejected = order.status === 'REJECTED'

  return (
    <Container>
      <EtaContainer>
        <EtaDisplay order={order}></EtaDisplay>
      </EtaContainer>
      <ProgressContainer>
        {!isRejected && (
          <StatusProgress
            currentCode={code}
            isDelivery={isDelivery}
          ></StatusProgress>
        )}
        <Title>
          <Heading
            as='h2'
            fontSize={18}
            fontWeight={isRejected ? 600 : 500}
            color='#262626'
          >
            {message}
          </Heading>
        </Title>
        {deliveryPin && <DeliveryPin pincode={deliveryPin}></DeliveryPin>}
        {order.isTrackable && (
          <MapContainer>
            <TrackMap
              config={config}
              destination={order.deliveryAddress}
              origin={order.store?.address}
              delivery={order.delivery}
            />
          </MapContainer>
        )}
        <CartBreakdownItem
          withLineBreak={!order.isTrackable}
          padding={order.isTrackable ? '0' : '12px 0 0'}
          label={
            <>
              {fulfillmentData[fulfillmentType].prefix}
              <Semibold>{fulfillmentData[fulfillmentType].text}</Semibold>
            </>
          }
          maxWidth={'100%'}
          withEllipsis={false}
          LeftIcon={fulfillmentData[fulfillmentType].icon}
        ></CartBreakdownItem>
      </ProgressContainer>
    </Container>
  )
}

const EtaContainer = styled.div<StyledHTMLElement, Required<Theme>>(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

const ProgressContainer = styled.div(() => ({
  paddingTop: '24px',
  gap: '16px',
  display: 'flex',
  flexDirection: 'column'
}))

const Title = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '8px',
  gap: '8px',
  '> h2, > h3': {
    margin: 0
  }
}))

const Container = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    fontWeight: theme.fonts.body.weight,
    fontFamily: theme.fonts.body.family,
    color: 'black'
  })
)

const MapContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    width: '100%',
    height: '250px',
    borderRadius: '12px',
    overflow: 'hidden',

    [theme?.mediaQueries?.viewport7]: {
      height: '400px'
    },

    // Remove border from Google Maps iframe
    '.gm-style iframe + div': {
      border: 'none!important;'
    }
  })
)

export default Banner
