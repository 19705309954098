import styled from '@emotion/styled'
import { useState } from 'react'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import FormInput from '../Inputs/FormInput'
import { Button, focusNextInput } from 'shop/components'
import Spinner from 'shop/components/Loader/Spinner'
import CustomerOptIn from '../Account/CustomerOptIn'
import { containsPlusInString } from '../Account/utils'
import { useAccount, useModal } from 'shop/hooks'
import useInputKeyEnterHandler from 'shop/hooks/useInputKeyEnterHandler'
import { useHistory, useLocation } from 'react-router-dom'
import { MdErrorOutline as ErrorIcon } from 'react-icons/md'
import { IntlPhoneNumber } from '../Inputs'
import { useForm } from 'react-hook-form'
import { handlePhoneNumberOnChange } from 'shop/components'
import { strippedContactNumPrefix } from '../Inputs/utils'
import { emailRegex } from 'shop/utils/common'

interface SignUpProps {
  linkBackToLogin: () => void
  inFulfillmentModal: boolean
}

/** List of inputs used in the sign up form */
export enum SignUpIds {
  firstName = 'first_name',
  lastName = 'last_name',
  email = 'email',
  contactNum = 'contact_num',
  contactNumPrefix = 'contact_num_prefix',
  password = 'password',
  passwordConfirmation = 'password_confirmation'
}

const SignupFields = ({ linkBackToLogin, inFulfillmentModal }: SignUpProps) => {
  const { pathname } = useLocation()
  const history = useHistory()
  const { closeModal } = useModal()
  const { handleSignUp } = useAccount()
  const form = useForm({
    mode: 'onBlur',
    defaultValues: {
      [SignUpIds.firstName]: '',
      [SignUpIds.lastName]: '',
      [SignUpIds.email]: '',
      [SignUpIds.contactNum]: '',
      [SignUpIds.contactNumPrefix]: '🇬🇧+44',
      [SignUpIds.password]: '',
      [SignUpIds.passwordConfirmation]: ''
    }
  })

  const { register, trigger, errors, watch } = form

  const [isOptedIn, setIsOptedIn] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const [showError, setShowError] = useState(false)

  const onSubmit = async (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault()
    setIsLoading(true)

    const isValid = await trigger()
    const values = form.getValues()

    if (!isValid) {
      setShowError(true)
      setIsLoading(false)
      return
    } else {
      const params = {
        firstName: values[SignUpIds.firstName],
        lastName: values[SignUpIds.lastName],
        email: values[SignUpIds.email],
        password: values[SignUpIds.password],
        contactNum: `${strippedContactNumPrefix(values[SignUpIds.contactNumPrefix])}${values[SignUpIds.contactNum]}`,
        marketingOptIn: isOptedIn
      }

      handleSignUp(params).then(({ success, error }) => {
        if (!error && !!success) {
          closeModal()
          // If login modal is opened from fulfillment modal, push query param
          if (inFulfillmentModal) {
            history.push({
              pathname: pathname,
              search: `?fulfillment=true`,
              state: {
                updateCart: true
              }
            })
          } else {
            history.replace({
              pathname: pathname,
              state: {
                updateCart: true
              }
            })
          }
          window.location.reload()
        } else {
          setShowError(true)
          setIsLoading(false)
        }
      })
    }
  }

  const inputIdList = [
    `${SignUpIds.firstName}-input`,
    `${SignUpIds.lastName}-input`,
    `${SignUpIds.email}-input`,
    `${SignUpIds.contactNum}`,
    `${SignUpIds.password}-input`
  ]

  useInputKeyEnterHandler({
    handleKeyPress: () => focusNextInput(inputIdList)
  })

  const values = form.getValues()

  const contactNumPrefix = watch(SignUpIds.contactNumPrefix)
  const contactNum = watch(SignUpIds.contactNum)

  return (
    <>
      <h1>Create an account</h1>
      <Subheading>Save your details for faster checkout next time</Subheading>
      <form
        onSubmit={onSubmit}
        id='registration-fields'
        data-testid='registration-fields'
        onKeyPress={(e) => {
          const focusedInput: HTMLInputElement | null =
            document.querySelector('input:focus')
          if (e.key === 'Enter' && focusedInput) {
            focusedInput.blur()
            return e.preventDefault()
          }
        }}
      >
        <InputContainer>
          <span>
            <FormInput
              id={SignUpIds.firstName}
              name={SignUpIds.firstName}
              value={values[SignUpIds.firstName]}
              formRef={register({ required: 'Please enter your first name' })}
              labelText='First Name'
              autoComplete='given-name'
              showError={!!errors[SignUpIds.firstName]}
              enterKeyHint='next'
              onBlur={() => trigger(SignUpIds.firstName)}
            />
            <FormInput
              id={SignUpIds.lastName}
              name={SignUpIds.lastName}
              value={values[SignUpIds.lastName]}
              formRef={register({ required: 'Please enter your last name' })}
              labelText='Last Name'
              autoComplete='family-name'
              showError={!!errors[SignUpIds.lastName]}
              enterKeyHint='next'
              onBlur={() => trigger(SignUpIds.lastName)}
            />
          </span>
          <FormInput
            id={SignUpIds.email}
            name={SignUpIds.email}
            value={values[SignUpIds.email]}
            formRef={register({
              required: 'Please enter your email address',
              pattern: { value: emailRegex, message: 'Invalid email address' },
              validate: (value) =>
                containsPlusInString(value)
                  ? 'Please remove “+” character'
                  : true
            })}
            labelText='Email'
            autoComplete='email'
            type='email'
            showError={!!errors[SignUpIds.email]}
            inputMode='email'
            enterKeyHint='next'
            onBlur={() => trigger(SignUpIds.email)}
          />
          <IntlPhoneNumber
            onBlur={() => trigger(SignUpIds.contactNum)}
            formHandle={form}
            labelText='Phone Number'
            showError={
              !!errors[SignUpIds.contactNum] ||
              !!errors[SignUpIds.contactNumPrefix]
            }
            prefixDropdownValue={contactNumPrefix}
            phoneNumberValue={contactNum}
            prefixDropdownId={SignUpIds.contactNumPrefix}
            prefixDropdownName={SignUpIds.contactNumPrefix}
            phoneNumberInputName={SignUpIds.contactNum}
            phoneNumberInputId={SignUpIds.contactNum}
            onNumberChange={handlePhoneNumberOnChange}
          />
          <FormInput
            id={SignUpIds.password}
            value={values[SignUpIds.password]}
            name='password'
            formRef={register({
              required: 'Please enter your password',
              minLength: {
                value: 8,
                message: 'Password must be at least 8 characters'
              }
            })}
            labelText='Password'
            showError={!!errors.password}
            type='password'
            enterKeyHint='next'
            onBlur={() => trigger(SignUpIds.password)}
          />
        </InputContainer>
        <OptInContainer>
          <CustomerOptIn
            isOptedIn={isOptedIn}
            onChange={() => setIsOptedIn(!isOptedIn)}
          />
        </OptInContainer>
        {!!Object.keys(errors).length && (
          <ErrorMessage>
            <ErrorIcon />
            {Object.values(errors)[0].message}
          </ErrorMessage>
        )}
        {!!showError && (
          <ErrorMessage>
            <ErrorIcon />
            Account creation failed. You might need to
            <span onClick={linkBackToLogin}>log in</span>.
          </ErrorMessage>
        )}
        <Button
          data-testid='create-account-button'
          type='submit'
          disabled={isLoading}
        >
          {isLoading && <Spinner />}
          Create account
        </Button>
        <LoginText>
          Already have an account? <span onClick={linkBackToLogin}>Log in</span>
        </LoginText>
      </form>
    </>
  )
}

const Subheading = styled.p<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    marginTop: '12px',
    marginBottom: 0
  })
)

const OptInContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    '& div': {
      marginTop: 0,
      marginBottom: '40px',
      color: '#8B8B8B',
      justifyContent: 'center'
    }
  })
)

const InputContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    padding: '40px 0',
    display: 'flex',
    gap: '24px',
    flexDirection: 'column',

    '& >span': {
      display: 'flex',
      gap: '16px'
    }
  })
)

const LoginText = styled.p<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  color: '#8B8B8B',
  fontSize: '16px',

  '& span': {
    color: '#000',
    paddingLeft: '5px',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}))

const ErrorMessage = styled.p(({ theme }: any) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
  color: theme.colors.state.error[5],
  fontSize: theme.fontSizes[1],
  fontWeight: theme.fontWeights.normal,

  '& span': {
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}))

export default SignupFields
