import { MouseEventHandler } from 'react'
import styled from '@emotion/styled'
import { FaChevronRight as RightArrowIcon } from 'react-icons/fa'
import { TbAlertTriangle as WarningIcon } from 'react-icons/tb'
import { GoInfo as InfoIcon } from 'react-icons/go'
import { useMediaQueries } from 'shop/hooks'
import { MessageType } from 'shop/types'
import { SimpleFormat } from 'shop/utils'

interface Props {
  title: string
  description: string
  messageType: MessageType
  handleOpenModal?: MouseEventHandler<HTMLDivElement>
  canOpenModal: boolean
}

export const Message = ({
  title,
  description,
  messageType,
  handleOpenModal = () => {},
  canOpenModal
}: Props) => {
  const { isMobile } = useMediaQueries()

  return (
    <>
      <Container
        messageType={messageType}
        isMobile={isMobile && canOpenModal}
        onClick={isMobile && canOpenModal ? handleOpenModal : () => {}}
      >
        {messageType === 'ANNOUNCEMENT' ? (
          <InfoIcon size={20} />
        ) : (
          <WarningIcon size={20} />
        )}
        <Content>
          <Title>
            <span>{title}</span>
            {isMobile && canOpenModal && <RightArrowIcon />}
          </Title>
          <Description shouldClampText={isMobile && canOpenModal}>
            <SimpleFormat>{description}</SimpleFormat>
          </Description>
        </Content>
      </Container>
    </>
  )
}

export const Container = styled.div<{
  messageType?: MessageType
  isMobile?: boolean
}>(({ messageType, isMobile }) => ({
  display: 'flex',
  gap: '12px',
  padding: '12px',

  backgroundColor: messageType === 'ANNOUNCEMENT' ? '#F7FFF4' : '#FFF5ED',
  boxShadow: '0px 4px 16px 0px #00000008',
  borderRadius: '12px',
  cursor: isMobile ? 'pointer' : 'auto'
}))

const Content = styled.div(() => ({
  display: 'flex',
  flexFlow: 'column',
  width: '100%',
  gap: '6px'
}))

const Title = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '18.75px',

  '& svg path': {
    fill: '#59595a'
  }
}))

const Description = styled.span<{ shouldClampText: boolean }>(
  ({ shouldClampText }) => ({
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16.41px',
    color: '#59595A',

    '& p': {
      margin: '0 0 6px 0'
    },

    ...(shouldClampText && {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': '1',
      '-webkit-box-orient': 'vertical'
    })
  })
)
