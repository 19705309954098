import gql from 'graphql-tag'

const UPDATE_CART = gql`
  mutation updateCart(
    $customer_details: jsonb!
    $recipient_details: jsonb
    $order_notes: String
    $dropoff_notes: String
    $cart_id: uuid!
    $gift_wrapped: Boolean
    $gift_wrap_message: String
    $details_disclosed: Boolean
    $custom_field_value: String
    $custom_field_name: String
  ) {
    update_carts_by_pk(
      pk_columns: { id: $cart_id }
      _set: {
        order_notes: $order_notes
        dropoff_notes: $dropoff_notes
        customer_details: $customer_details
        recipient_details: $recipient_details
        gift_wrapped: $gift_wrapped
        gift_wrap_message: $gift_wrap_message
        details_disclosed: $details_disclosed
        custom_field_value: $custom_field_value
        custom_field_name: $custom_field_name
      }
    ) {
      id
      customer_details
      order_notes
      gift_wrapped
      gift_wrap_message
      details_disclosed
      custom_field_value
      custom_field_name
    }
  }
`

const GET_PAY_NEXT_STEP = gql`
  mutation payForCart($cartId: ID!) {
    payForCart(cartId: $cartId) {
      nextStep
      transactionId
    }
  }
`

const INTEND_PAYMENT_FOR_CART = gql`
  mutation payForCart(
    $cartId: ID!
    $savePaymentMethod: Boolean
    $paymentMethodId: ID
  ) {
    payForCart(
      cartId: $cartId
      savePaymentMethod: $savePaymentMethod
      paymentMethodId: $paymentMethodId
    ) {
      clientSecret
      nextStep
      connectAccountId
      transactionId
    }
  }
`

const LOCK_CART = gql`
  mutation payForCart($cartId: ID!, $lock: Boolean) {
    payForCart(cartId: $cartId, lock: $lock) {
      nextStep
    }
  }
`

export { UPDATE_CART, GET_PAY_NEXT_STEP, INTEND_PAYMENT_FOR_CART, LOCK_CART }
