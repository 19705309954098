import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import ContentLoader from 'react-content-loader'

const OrderPageLoader = () => {
  return (
    <Container>
      <ContentSection>
        <ContentLoader speed={2}>
          <rect x='0' y='0' width='100%' height='50%' border-radius='12px' />
          <rect x='0' y='52%' width='100%' height='40%' border-radius='12px' />
          <rect x='0' y='84%' width='100%' height='50%' border-radius='12px' />
        </ContentLoader>
      </ContentSection>
    </Container>
  )
}

const Container = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexGrow: 1,
    minHeight: '95vh',
    justifyContent: 'center',

    [theme.mediaQueries.viewport5]: {
      padding: '40px'
    },

    [theme.mediaQueries.viewport12]: {
      padding: '32px 64px 32px 64px'
    }
  })
)

const ContentSection = styled.div<StyledHTMLElement, Required<Theme>>(() => ({
  display: 'flex',
  width: '650px',

  '& svg': {
    borderRadius: '12px'
  }
}))

export default OrderPageLoader
