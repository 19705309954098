import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { TipType } from './TippingV2'
import { cssSetColorOpacity } from 'shop/theme/helpers'
import CustomTip from './CustomTip'
import { TipValues } from 'shop/types/cart'
import { formatMoney } from '../Cart/utils'
import { isTipMoreThanZero } from './utils'

type TippingListProps = {
  tippingValues: TipValues[]
  setTipAmount: (value: string) => void
  tipType: TipType
  setTipType: (type: TipType) => void
  currentTipValue: string | null
}

type TippingItemProps = {
  isSelected: boolean
  tippingPercentage: string
  tippingValue: string
  setSelectedTipItem: (value: string) => void
  setTipAmount: (value: string) => void
  setTipType: (type: TipType) => void
}

const TippingList = ({
  tippingValues,
  setTipAmount,
  tipType,
  setTipType,
  currentTipValue
}: TippingListProps) => {
  const [selectedTip, setSelectedTipItem] = useState<String>('')

  const getDefaultSelectedTip = (tippingValues: TipValues[]) => {
    if (!tippingValues.length) {
      setTipAmount('0.00')
      setSelectedTipItem('')
      return
    }
    if (!!currentTipValue && isTipMoreThanZero(currentTipValue)) {
      const prevSelectedTip = tippingValues.find((tippingValue) => {
        return tippingValue.amount === currentTipValue
      })
      // found previously selected tip
      if (prevSelectedTip) {
        setTipAmount(prevSelectedTip.amount)
        setTipType('standard')
        setSelectedTipItem(`tip-${prevSelectedTip.percentage}`)
        return
      }
      // else must have been a custom amount
      setTipAmount(currentTipValue)
      setTipType('custom')
      setSelectedTipItem('tip-custom')
      return
    }
    if (tippingValues.length > 2) {
      setTipAmount(tippingValues[1].amount)
      setSelectedTipItem(`tip-${tippingValues[1].percentage}`)
      return
    }
    setTipAmount(tippingValues[0].amount)
    setSelectedTipItem(`tip-${tippingValues[0].percentage}`)
    return
  }

  // select default tip on load
  useEffect(() => {
    getDefaultSelectedTip(tippingValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TippingListContainer>
      <TippingItemsContainer>
        {tippingValues.map(({ amount, percentage }) => (
          <TippingItem
            key={`tip-${percentage}`}
            isSelected={selectedTip === `tip-${percentage}`}
            setSelectedTipItem={setSelectedTipItem}
            setTipAmount={setTipAmount}
            tippingPercentage={percentage}
            tippingValue={amount}
            setTipType={setTipType}
          />
        ))}
        <CustomTippingItem
          key={'tip-custom'}
          isSelected={selectedTip === 'tip-custom'}
          setSelectedTipItem={setSelectedTipItem}
          setTipType={setTipType}
        />
      </TippingItemsContainer>
      <CustomTipContainer showCustomTip={tipType === 'custom'}>
        <CustomTip
          currentTipValue={currentTipValue}
          setTipAmount={setTipAmount}
        />
      </CustomTipContainer>
    </TippingListContainer>
  )
}

const TippingItem = ({
  isSelected,
  tippingPercentage,
  tippingValue,
  setSelectedTipItem,
  setTipAmount,
  setTipType
}: TippingItemProps) => {
  const handleClick = () => {
    if (isSelected) {
      return
    }
    setTipAmount(tippingValue)
    setSelectedTipItem(`tip-${tippingPercentage}`)
    setTipType('standard')
  }
  return (
    <TippingItemContainer
      data-testid={`tip-${tippingPercentage}`}
      isSelected={isSelected}
      onClick={handleClick}
    >
      <TipValueText>{tippingPercentage}%</TipValueText>
      <TipValueText>{formatMoney(parseFloat(tippingValue))}</TipValueText>
    </TippingItemContainer>
  )
}

const CustomTippingItem = ({
  isSelected,
  setSelectedTipItem,
  setTipType
}: Omit<
  TippingItemProps,
  'tippingPercentage' | 'tippingValue' | 'setTipAmount'
>) => {
  const handleClick = () => {
    if (isSelected) {
      return
    }
    setSelectedTipItem(`tip-custom`)
    setTipType('custom')
    document.getElementById('custom-tip-input')?.focus()
  }
  return (
    <TippingItemContainer
      data-testid={'tip-custom'}
      isSelected={isSelected}
      onClick={handleClick}
    >
      <TipValueText>Custom amount</TipValueText>
    </TippingItemContainer>
  )
}

const TippingListContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',

    [theme.mediaQueries.viewport7]: {
      gap: '32px'
    }
  })
)

const CustomTipContainer = styled.div<
  StyledHTMLElement & { showCustomTip: boolean },
  Required<Theme>
>(({ theme, showCustomTip }) => ({
  display: 'flex',
  height: showCustomTip ? '46px' : '0',
  opacity: showCustomTip ? 1 : 0,
  overflow: showCustomTip ? 'inherit' : 'hidden',
  transition: 'all ease-in-out 200ms'
}))

const TippingItemsContainer = styled.ul<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-evenly',
    gap: '12px',
    overflowX: 'auto',
    margin: 0,
    textAlign: 'center',

    [theme.mediaQueries.viewport7]: {
      gap: '24px'
    }
  })
)

const TippingItemContainer = styled.li<
  StyledHTMLElement & { isSelected: boolean },
  Required<Theme>
>(({ theme, isSelected }) => ({
  display: 'flex',
  position: 'relative',
  flexGrow: 1,
  height: '82px',
  width: '100%',
  maxWidth: '82px',
  flexDirection: 'column',
  backgroundColor: isSelected
    ? theme.colors['primary']
    : cssSetColorOpacity(theme.colors['primary'], 10),
  color: isSelected ? '#fff' : theme.colors['primary'],
  borderRadius: '12px',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '5px',
  cursor: 'pointer',
  padding: '0 10px',

  [theme.mediaQueries.viewport7]: {
    aspectRatio: '1/1',
    '&:hover': {
      backgroundColor: isSelected
        ? cssSetColorOpacity(theme.colors['primary'], 80)
        : cssSetColorOpacity(theme.colors['primary'], 30)
    }
  },

  '& h1': {
    fontSize: '16px'
  },
  '& h2': {
    fontSize: '14px',
    fontWeight: 'lighter'
  }
}))

const TipValueText = styled.p<
  StyledHTMLElement & { fontSize?: string; fontWeight?: number }
>(({ fontSize, fontWeight }) => ({
  fontSize: fontSize || '16px',
  fontWeight: fontWeight || 500,
  margin: 0
}))

export default TippingList
