import styled from '@emotion/styled'
import { isTableOrder } from 'shop/components/Cart/utils'
import { OrderAgainButton } from 'shop/components/Order/Buttons'
import { Content } from './orderCommonStyles'
import { Order as OrderV2 } from 'shop/types/cart'

// FIXME: Creating a temp order interface to get around lack of old Order type that was previously removed
interface Order {
  cart_id: string
  metadata: {
    pickup_type: string
  }
}

// FIXME: DeliveryActions is used for Order Again which is currently disabled.
// If using this component ensure order object is up to date.
const DeliveryActions = ({
  order,
  orderV2,
  isLoggedIn
}: {
  order: Order
  orderV2: OrderV2
  isLoggedIn?: boolean
}) => {
  const { metadata } = order
  const isDineIn = isTableOrder(metadata)

  // Temporarily disable reordering until consumer API adds support for it
  const reorderingEnabled = false

  const isOrderAgain = isLoggedIn && reorderingEnabled

  return (
    <Root>
      <Content>
        {isOrderAgain && (
          <ButtonWrapper>
            <OrderAgainButton
              {...{
                cartId: order.cart_id,
                storeId: orderV2.store.id,
                storeSlug: orderV2.store.slug,
                isDineIn: isDineIn
              }}
            />
          </ButtonWrapper>
        )}
      </Content>
    </Root>
  )
}

const Root = styled.div(() => ({
  padding: '16px'
}))

const ButtonWrapper = styled.div(() => ({
  '&:not(:first-of-type)': {
    marginTop: '12px'
  },
  display: 'flex',
  gap: '10px',
  justifyContent: 'center'
}))

export default DeliveryActions
