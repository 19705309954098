import React from 'react'
import styled from '@emotion/styled'
import { InputGroup } from '../Controls'
import { Fields, Switch } from './FormElements'
import { UseFormMethods } from 'react-hook-form'
import { FormFields } from './types'
import { HalfWidthFlexContainer } from './commonStyles'
import {
  RECIPIENT_INPUTS,
  handlePhoneNumberOnBlur,
  handlePhoneNumberOnChange
} from './utils'
import FormInput from '../Inputs/FormInput'
import { IntlPhoneNumber } from '../Inputs'

type Props = { formHandle: UseFormMethods<FormFields> }

const RecipientDetails = ({ formHandle }: Props) => {
  const { register, errors, watch, trigger, setValue } = formHandle
  const forSomeoneElse = watch('forSomeoneElse')
  const recipientDetails = watch('recipientDetails')

  const { firstNameInputId, lastNameInputId, phonePrefixId, phoneInputId } =
    RECIPIENT_INPUTS

  const handlePhoneInputsBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handlePhoneNumberOnBlur(
      e,
      setValue,
      trigger,
      'recipientDetails.contactNumber'
    )
  }

  return (
    <div>
      <Fields>
        <SwitchWrapper enabled={!!forSomeoneElse}>
          <Switch
            formRef={register}
            name={'forSomeoneElse'}
            labelText='Order for someone else'
            color='#BFBFBF'
          />
        </SwitchWrapper>
        {forSomeoneElse && (
          <>
            <InputGroup templateColumns='1fr' gridGap='24px'>
              <HalfWidthFlexContainer>
                <FormInput
                  id={firstNameInputId}
                  labelText='Recipient First Name'
                  name={firstNameInputId}
                  value={recipientDetails?.firstName || ''}
                  formRef={register({
                    required: "Please enter the recipient's first name",
                    validate: (value: string) =>
                      value.trim().length > 0
                        ? true
                        : "Please enter the recipient's first name"
                  })}
                  showError={!!errors?.recipientDetails?.firstName}
                />
                <FormInput
                  id={lastNameInputId}
                  labelText='Recipient Last Name'
                  name={lastNameInputId}
                  value={recipientDetails?.lastName || ''}
                  formRef={register({
                    required: "Please enter the recipient's last name",
                    validate: (value: string) =>
                      value.trim().length > 0
                        ? true
                        : "Please enter the recipient's last name"
                  })}
                  showError={!!errors?.recipientDetails?.lastName}
                />
              </HalfWidthFlexContainer>
              <IntlPhoneNumber
                onNumberChange={handlePhoneNumberOnChange}
                onBlur={handlePhoneInputsBlur}
                formHandle={formHandle}
                prefixDropdownId={phonePrefixId}
                labelText='Phone Number'
                prefixDropdownValue={recipientDetails?.contactNumPrefix}
                prefixDropdownName={'recipientDetails[contactNumPrefix]'}
                phoneNumberInputName={'recipientDetails.contactNumber'}
                phoneNumberInputId={phoneInputId}
                phoneNumberValue={recipientDetails?.contactNumPrefix || ''}
                showError={
                  !!errors?.recipientDetails?.contactNumber ||
                  !!errors?.recipientDetails?.contactNumPrefix
                }
              />
            </InputGroup>
          </>
        )}
      </Fields>
    </div>
  )
}

const SwitchWrapper = styled.div(({ enabled }: { enabled: boolean }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: enabled ? '32px' : '0'
}))

export default RecipientDetails
