import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split,
  ApolloLink,
  Observable
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { ApolloProvider, useApolloClient } from '@apollo/client'
import { ShopConfig } from 'shop/types'
import { Socket as PhoenixSocket } from 'phoenix'
import * as AbsintheSocket from '@absinthe/socket'
import {
  AbsintheSocketLink,
  createAbsintheSocketLink
} from '@absinthe/socket-apollo-link'
import { onError } from '@apollo/client/link/error'
import { removeLoginCredentials } from 'shop/utils'
import { getErrorMessage, isNetworkErrorUnauthorized } from 'shop/utils/common'

const scheme = (proto: string) => {
  if (process.env.NODE_ENV === 'production') {
    return 'https'
  } else {
    if (process.env.REACT_APP_GRAPH_HOST?.includes('slerp.io')) return 'https'
    return window.location.protocol === 'https:' ? `${proto}s` : proto
  }
}

export const consumerClientContext = { context: { clientName: 'consumerApi' } }

export const GRAPHQL_ENDPOINT = `${scheme('http')}://${process.env.REACT_APP_GRAPH_HOST}/v1/graphql`

const WSS_CONSUMER_ENDPOINT = `wss://api.${process.env.REACT_APP_CONSUMER_GRAPH_HOST}/live_orders`

let consumerApiUri: string

/**
 * This creates a new Apollo Client.
 * This is being used by ShopClient or can be called as a standalone function
 *
 * @param domain
 * @param apiKey
 */
const createClient = (domain: string, apiKey: string, merchantId: string) => {
  // Use consumer API when context clientName 'consumerApi' is set, else use Hasura API
  const client = new ApolloClient({
    link: getClientLinks(domain, apiKey, merchantId),
    cache: new InMemoryCache({
      addTypename: false
    })
  })
  return client
}

/** Returns all links for setting up the Apollo Client */
const getClientLinks = (
  domain: string,
  apiKey: string,
  merchantId: string,
  absintheSocketLink?: AbsintheSocketLink
) => {
  const httpLink = createHttpLink({
    uri: GRAPHQL_ENDPOINT,
    headers: {
      authorization: apiKey ? `Bearer ${apiKey}` : ''
    }
  })

  let apiAndWebsocketLink = httpLink
  if (absintheSocketLink) {
    apiAndWebsocketLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      absintheSocketLink as any,
      httpLink
    )
  }

  const consumerApiLink = createHttpLink({
    uri: consumerApiUri,
    credentials: 'include'
  })

  /** Error link catches unauthorized errors and reset user local values */
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    // Bypass global handler for consumer cart query, handled locally
    const isCart = operation.operationName === 'cart'
    if (isCart) return

    if (isNetworkErrorUnauthorized(networkError)) {
      removeLoginCredentials(domain)
      window.location.reload()
    }

    const errorMessage = getErrorMessage(graphQLErrors)

    // Remove customer id/api key/csrf token from local storage if unauthorized
    if (errorMessage) {
      const isOrder = operation.operationName === 'order'
      // Bypass global handler for /track page, handled locally
      if (errorMessage === 'unauthorized') {
        if (isOrder) return
        removeLoginCredentials(domain)
        window.location.reload()
      }
      if (errorMessage === 'auth_missing') {
        removeLoginCredentials(domain)
        if (!isOrder) window.location.reload()
      }
    }
  })

  /** Sets CSRF token in request headers & local storage for consumer API */
  const consumerResponseLink = new ApolloLink((operation, forward) => {
    if (forward) {
      // Get the csrf token from local storage if it exists
      const csrfToken = localStorage.getItem(`${domain}-csrf`)
      // Dynamically adds the csrf token to request headers
      csrfToken &&
        operation.setContext(({ headers = {} }) => ({
          headers: {
            ...headers,
            'x-csrf-token': csrfToken
          }
        }))

      // Gets csrf token from response headers and sets it in local storage
      return forward(operation).map((response) => {
        const context = operation.getContext()
        const responseHeaders = context.response.headers
        const csrfToken = responseHeaders.get('x-csrf-token')
        if (csrfToken) {
          localStorage.setItem(`${domain}-csrf`, csrfToken)
        }
        return response
      })
    } else {
      return Observable.of()
    }
  })

  /** Combines response/error link functions & consumer http/ws link */
  const consumerLinkWithMiddleware = ApolloLink.from([
    getRequestHeadersMiddleware(merchantId, domain),
    errorLink,
    consumerResponseLink,
    consumerApiLink
  ])

  return split(
    (operation) => operation.getContext().clientName === 'consumerApi',
    consumerLinkWithMiddleware,
    ApolloLink.from([
      getRequestHeadersMiddleware(merchantId, domain),
      apiAndWebsocketLink
    ])
  )
}

/** Create an Absinthe Socket Link and split it with the other global links. */
const getAbsintheSocketLink = (
  trackingToken: string,
  domain: string,
  apiKey: string,
  merchantId: string
) => {
  const phoenixSocket = new PhoenixSocket(WSS_CONSUMER_ENDPOINT, {
    params: () => ({ 'x-order-tracking-token': trackingToken })
  })

  const absintheSocket = AbsintheSocket.create(phoenixSocket)
  const websocketLink = createAbsintheSocketLink(absintheSocket)
  return getClientLinks(domain, apiKey, merchantId, websocketLink)
}

const getRequestHeadersMiddleware = (merchantId: string, domain: string) =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'x-hasura-merchant-id': merchantId || domain, // fallback to "merchant.slug"
        'x-hasura-operation-name': operation.operationName
      }
    }))

    return forward(operation)
  })

/**
 *  Shop props
 */
interface ShopProps {
  config: ShopConfig
  children: React.ReactNode
}

/**
 *  A hook to return a wrapped graphql client
 *
 *  @returns ApolloClient
 */
const useShopClient = () => {
  return useApolloClient()
}

/**
 * The Slerp context provider that allows the app to connect
 * to the GraphQL endpoint
 *
 * @param props An object that accepts a config (ShopConfig) and its children.
 *
 * @example
 *
 *  const config = {
 *   apiKey: 'someapikey',
 *   domain: 'crosstown'
 *  }
 *
 *  <ShopClient config={config}>
 *    <SomeComponent />
 *  </ShopClient>
 *
 */
const ShopClient = (props: ShopProps) => {
  const {
    config: { domain, apiKey, consumerApiHost }
  } = props

  // Set consumer API URI dynamically on load to get consumer api host from root element /config
  if (consumerApiHost) {
    consumerApiUri = `${scheme('http')}://${consumerApiHost}/api/consumer/v2`
  } else {
    // Temporarily fallback: use old uri if partner does not have attribute set
    consumerApiUri = `${scheme('http')}://${domain}.${
      process.env.REACT_APP_CONSUMER_GRAPH_HOST
    }/api/consumer/v2`
  }

  return (
    <ApolloProvider client={createClient(domain, apiKey, '')}>
      {props.children}
    </ApolloProvider>
  )
}

export * from './queries'
export * from './subscriptions'
export * from './mutations/CheckoutMutations'
export { useShopClient, createClient, getAbsintheSocketLink, getClientLinks }
export default ShopClient
