import { Store, StoreSettings } from 'shop/components/Landing/types'
import { mapKeysToNewProperty } from 'shop/utils/common'

// New queried data uses paths rather than querying the whole jsonb "settings".
type RawStore = Omit<Store, 'settings'> & StoreSettings
/**
 * Now we are directly querying the store settings with paths, we need to put them back into their settings object.
 * This is to not incur a large amount of changes whilst referencing store.settings across the code.
 */
export const mapStoreSettingsIntoObject = (stores: RawStore[]): Store[] => {
  const storeSettingsKeys = [
    'delivery_radius',
    'scheduled_delivery',
    'scheduled_pickup',
    'same_day_pickup',
    'same_day_delivery',
    'table_enabled',
    'table_welcome_message',
    'table_count',
    'table_allow_counter_pickup',
    'table_counter_pickup_message',
    'table_choices',
    'asap_only',
    'asap_enabled',
    'delivery_interval',
    'pickup_interval',
    'busy_preparation_time',
    'moderate_preparation_time',
    'quiet_preparation_time',
    'prep_mode',
    'delivery_area',
    'busy_delivery_area',
    'busy_delivery_area_enabled'
  ]

  const mappedStores = stores.map((store) =>
    mapKeysToNewProperty(store as Object, storeSettingsKeys, 'settings')
  ) as Store[]
  return mappedStores
}
