import { useEffect } from 'react'
import styled from '@emotion/styled'
import CloseButton from './CloseButton'
import Backdrop from './Backdrop'
import useEscModalHandler from 'shop/hooks/useEscModalHandler'
import { useMediaQueries } from 'shop/hooks'
import { fadeInOutMs } from '../Landing/utils'
import { fadeIn, fadeOut, slideUp } from '../Shop/commonStyles'

interface Props {
  children: React.ReactNode
  handleCloseModal?: () => void
  type?: string
  canClose?: boolean
  zIndex?: number
  styles?: { [key: string]: string | number }
  inModal?: boolean
  newFulfillmentModal?: boolean
  isOpen?: boolean
  isOverlay?: boolean
  modalId?: string
  modalAlign?: string
}

const CenterModal = ({
  children,
  handleCloseModal,
  type,
  canClose,
  zIndex,
  styles,
  inModal,
  newFulfillmentModal,
  isOpen,
  isOverlay = false,
  modalId,
  modalAlign = 'center'
}: Props) => {
  const { isMobile } = useMediaQueries()

  useEscModalHandler({ onClose: handleCloseModal })
  // Lock screen scroll behind modal whilst it's open
  // BUT don't unlock the scroll if already in a modal
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    // Distinguishes between parent modal/dropdowns & sets css transition styles directly
    let fulfillmentModal: HTMLElement | null
    let overlay: HTMLElement | null
    if (!newFulfillmentModal && isOverlay) {
      fulfillmentModal = document.getElementById('fulfillmentModal')
      overlay = document.getElementById('overlay')

      // Hide main modal & set timeout to allow fade to take place
      setTimeout(() => {
        if (fulfillmentModal && isOpen) {
          fulfillmentModal.style.visibility = 'hidden'
        }
      }, fadeInOutMs)

      if (overlay) {
        overlay.style.visibility = 'visible'
      }
    }

    return () => {
      if (!inModal) {
        document.body.style.overflow = 'visible'
      }
      // Make main modal visible and allow fade to take place
      setTimeout(() => {
        if (fulfillmentModal && isOpen) {
          fulfillmentModal.style.visibility = 'visible'
        }
      }, fadeInOutMs)
    }
  }, [inModal, newFulfillmentModal, isOverlay])

  return (
    <ModalContainer
      id={modalId}
      zIndex={zIndex}
      style={{ alignItems: modalAlign }}
    >
      <Dialog
        id={newFulfillmentModal && !isOverlay ? 'fulfillmentModal' : 'overlay'}
        {...{ type, styles, newFulfillmentModal, isOpen, isMobile, isOverlay }}
      >
        {canClose && (
          <CloseButton variant='newModal' handleCloseModal={handleCloseModal} />
        )}
        {children}
      </Dialog>
      <Backdrop
        newFulfillmentModal={newFulfillmentModal}
        isOpen={isOpen}
        isOverlay={isOverlay}
        {...(!newFulfillmentModal && { onClick: handleCloseModal })}
      />
    </ModalContainer>
  )
}

CenterModal.defaultProps = {
  canClose: true
}

const ModalContainer = styled.div(({ zIndex }: { zIndex?: number }) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: zIndex || 175,
  display: 'flex',
  alignItems: 'center'
}))

const fadeInOrFadeOut = (isMobile: boolean, isOpen: boolean) => {
  if (!isMobile) {
    if (isOpen) {
      return `${fadeIn} 225ms linear forwards`
    } else {
      return `${fadeOut} 225ms linear forwards`
    }
  } else return 'auto'
}

/** Returns correct styles for new and old modals */
const setModalStyles = (
  type: string,
  newFulfillmentModal: boolean,
  theme: any,
  isOpen: boolean,
  isMobile: boolean
) => {
  if (newFulfillmentModal) {
    return {
      position: 'fixed',
      bottom: '0px',
      margin: '0 auto',
      maxWidth: '530px',
      overflowY: 'visible',
      width: '100%',
      borderBottomRightRadius: 'unset',
      borderBottomLeftRadius: 'unset',
      [theme.mediaQueries.viewport5]: {
        borderRadius: '12px',
        position: 'relative',
        margin: '24px auto',
        animation: fadeInOrFadeOut(isMobile, isOpen),
        WebkitBackfaceVisibility: 'hidden',
        MozBackfaceVisibility: 'hidden',
        WebkitTransform: 'translate3d(0, 0, 0)',
        MozTransform: 'translate3d(0, 0, 0)'
      }
    }
  } else {
    return {
      maxWidth: type === 'wide' ? '720px' : '480px',
      maxHeight: '90%'
    }
  }
}

const Dialog = styled.div(
  ({ theme, type, styles, newFulfillmentModal, isOpen, isMobile }: any) => ({
    animation: `${slideUp} 200ms ease-out`,
    transition: 'margin 400ms',
    position: 'relative',
    overflowY: 'auto',
    backgroundColor: 'white',
    display: 'block',
    zIndex: 500,
    fontFamily: theme.fonts.normal,
    margin: '24px auto',
    width: '90%',
    borderRadius: '12px',
    '> h1': {
      marginBottom: '24px'
    },
    ...setModalStyles(type, newFulfillmentModal, theme, isOpen, isMobile),
    ...styles
  })
)

export default CenterModal
