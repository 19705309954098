import { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { Button } from '../Controls'
import CartItem from './CartItem'
import { CartItem as CartItemProps } from 'shop/components/Landing'
import AlertMessage from './AlertMessage'
import SideModal from '../Modal/SideModal'
import { useCart, useModal, useShop, useCartValidation } from 'shop/hooks'
import {
  computeCartTotal,
  filterAlcoholicProducts,
  formatMinimumOrderValue
} from './utils'
import { priceWithVat } from '../Product/utils'
import SpinnerModal from 'shop/components/Loader/SpinnerModal'
import { Cart } from '../Landing'
import { TrackableEvent } from 'tracker'
import { trackUserActionsFBPixel, trackUserActionsGA4 } from 'tracker'
import Heading from '../Heading'
import useRouter from 'use-react-router'
import { BsCart2 } from 'react-icons/bs'
import {
  createEcommEventDataFromCart,
  merchantGA4EcommTrackViewCart,
  slerpGA4EcommTrackViewCart
} from 'tracker/GA/ecommerce'
interface Props {
  closeCartModal?: () => void
}

type ValidationResult = {
  invalidCartItems: CartItemProps[]
  validCartItems: CartItemProps[]
  unavailableCartItems: CartItemProps[]
  minimumOrderValue: number | string
  isMinimumOrderValue: boolean
}

const CartModal = ({ closeCartModal }: Props) => {
  const { closeModal } = useModal()
  const { history } = useRouter()
  const { cartSession, config, useShopClient, setIsStoreLoading } = useShop()
  const client = useShopClient()
  const { loadCart } = useCart()
  const { validateCart } = useCartValidation()
  const [cartItems, cacheCartItems] = useState<CartItemProps[]>([])
  const [invalidItems, setInvalidItems] = useState<CartItemProps[]>([])
  const [unavailableItems, setUnavailableItems] = useState<CartItemProps[]>([])
  const [alcoholicProducts, setAlcoholicProducts] = useState<CartItemProps[]>(
    []
  )
  const [loadingMessage, setLoadingMessage] = useState('')
  const [cartTotal, setCartTotal] = useState(<></>)
  const [minimumOrderValue, setMinimumOrderValue] = useState('')
  const [isMinimumOrderValue, setIsMinimumOrderValue] = useState(true)

  const [boxShadow, setBoxShadow] = useState(false)
  const itemsContainerRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLDivElement>(null)

  cartSession.onCartUpdating = () => {
    setLoadingMessage('Updating cart')
  }

  cartSession.onCartUpdate = () => {
    loadCartItems()
  }

  const loadCartItems = () => {
    if (!cartSession.cartId) {
      cacheCartItems([])
      setAlcoholicProducts([])
      return
    }

    setLoadingMessage('Loading cart')

    return loadCart()
      .then(async (cart: Cart | null) => {
        setIsStoreLoading(false)

        if (!cart) {
          return []
        }

        cartSession.setCart(cart)

        const total = computeCartTotal(cart.cart_items)

        validateCart(cart, total, config, client)
          .then(
            ({
              validCartItems,
              invalidCartItems,
              unavailableCartItems,
              minimumOrderValue,
              isMinimumOrderValue
            }: ValidationResult) => {
              cacheCartItems(validCartItems)
              setInvalidItems(invalidCartItems)
              setUnavailableItems(unavailableCartItems)
              setMinimumOrderValue(formatMinimumOrderValue(minimumOrderValue))
              setIsMinimumOrderValue(isMinimumOrderValue)
            }
          )
          .then(() => setLoadingMessage(''))

        setCartTotal(<>£{total.toFixed(2)}</>)

        const eventData = {
          cart_id: cart.id,
          products: cart.cart_items.map((item) => {
            const p = item.product_variant.product
            return {
              product_id: p.id,
              name: p.name,
              currency: 'gbp', // FIXME: Update this once multi-currency is supported
              price: priceWithVat(item.variant_price, item.variant_vat),
              quantity: item.quantity
            }
          })
        }

        const ecommerceEventData = createEcommEventDataFromCart(cart)

        const body = {
          category: 'Cart',
          action: TrackableEvent.CartViewed
        }

        trackUserActionsGA4(body, 'slerpGA4Tracking')

        // legacy tracking
        trackUserActionsFBPixel(TrackableEvent.CartViewed, eventData)
        trackUserActionsGA4(body, 'merchantGA4Tracking')

        slerpGA4EcommTrackViewCart(ecommerceEventData)
        merchantGA4EcommTrackViewCart(ecommerceEventData)

        return cart.cart_items
      })
      .then((items: CartItemProps[]) => {
        const products = filterAlcoholicProducts(items)
        setAlcoholicProducts(products)
      })
  }

  useEffect(() => {
    loadCartItems()
  }, []) // eslint-disable-line
  // 'client' is mutable so I have no choice :(

  /** Check if cartItems overlap with checkout button and set boolean to apply styling if so */
  useEffect(() => {
    const button = buttonRef.current
    const itemsContainer = itemsContainerRef.current

    if (
      itemsContainer &&
      button &&
      button.getBoundingClientRect().top <=
        itemsContainer.getBoundingClientRect().bottom
    ) {
      setBoxShadow(true)
    } else {
      setBoxShadow(false)
    }
  }, [cartItems])

  const EmptyCart = () => {
    if (
      loadingMessage === '' &&
      cartItems &&
      cartItems.length === 0 &&
      invalidItems &&
      invalidItems.length === 0 &&
      unavailableItems &&
      unavailableItems.length === 0
    ) {
      return (
        <EmptyCartContainer>
          <CartIcon size='24px' />
          <EmptyCartText>Your Cart is empty</EmptyCartText>
          <Button onClick={() => closeModal('cart')}>View Menu</Button>
        </EmptyCartContainer>
      )
    }

    return <></>
  }

  const checkout = () => {
    if (!cartSession.cart) return
    closeModal('cart')
    return history.push('/checkout')
  }

  const disableCheckout =
    (unavailableItems && unavailableItems.length > 0) ||
    (invalidItems && invalidItems.length > 0) ||
    !isMinimumOrderValue

  return (
    <SideModal handleCloseModal={closeCartModal}>
      <Heading as='h2' fontWeight={600}>
        Your cart
      </Heading>
      {alcoholicProducts && alcoholicProducts.length > 0 && (
        <AlertMessage
          heading='You must be over 18 to purchase some products'
          subheading='You may be required to show your ID'
        />
      )}
      {!isMinimumOrderValue && (
        <AlertMessage
          heading={`You must spend a minimum of £${minimumOrderValue} to place your order`}
          subheading=''
          type='info'
        />
      )}
      <EmptyCart />
      <Items ref={itemsContainerRef}>
        {cartItems &&
          cartItems.length > 0 &&
          cartItems.map((cartItem: CartItemProps, index: number) => (
            <CartItem
              key={cartItem.id}
              {...{ cartItem }}
              canEditQuantity={true}
              testLabel={'cart'}
              index={index}
            />
          ))}
      </Items>

      {invalidItems && invalidItems.length > 0 && (
        <AlertMessage
          heading='Pricing of these item(s) have changed.'
          subheading='Please remove and re-add these item(s) to your cart.'
          type='error'
        />
      )}

      <Items>
        {invalidItems &&
          invalidItems.length > 0 &&
          invalidItems.map((cartItem: CartItemProps, index: number) => (
            <CartItem
              key={cartItem.id}
              {...{ cartItem }}
              canEditQuantity={true}
              testLabel={'invalid'}
              index={index}
            />
          ))}
      </Items>

      {unavailableItems && unavailableItems.length > 0 && (
        <AlertMessage
          heading='These item(s) are not available in this store'
          subheading='Please remove these item(s) from your cart.'
          type='error'
        />
      )}

      <Items>
        {unavailableItems &&
          unavailableItems.length > 0 &&
          unavailableItems.map((cartItem: CartItemProps, index: number) => (
            <CartItem
              key={cartItem.id}
              {...{ cartItem }}
              canEditQuantity={true}
              index={index}
              testLabel={'unavailable'}
            />
          ))}
      </Items>

      {cartItems && cartItems.length > 0 && (
        <Actions ref={buttonRef} buttonBoxShadowBool={boxShadow}>
          <Button
            testId='checkoutButton'
            onClick={checkout}
            disabled={disableCheckout}
          >
            Go to checkout・{cartTotal}
          </Button>
        </Actions>
      )}
      {loadingMessage !== '' && <SpinnerModal message={loadingMessage} />}
    </SideModal>
  )
}

interface ActionProps {
  buttonBoxShadowBool: boolean
}

const showBoxShadow = (showBoxShadowBool: boolean) => {
  if (showBoxShadowBool)
    return {
      WebkitBoxShadow: '0px -1px 0px rgb(50 50 50 / 5%)',
      MozBoxShadow: '0px -1px 0px rgb(50 50 50 / 5%)',
      boxShadow: '0px -1px 0px rgb(50 50 50 / 5%)',
      backgroundColor: 'white'
    }
  return {}
}

const Actions = styled.div<ActionProps>(({ buttonBoxShadowBool }) => ({
  marginTop: 'auto',
  textAlign: 'center',
  ...showBoxShadow(buttonBoxShadowBool),
  position: 'sticky',
  bottom: '0',
  padding: '32px 0'
}))

const Items = styled.div(() => ({
  flex: '0 0 auto',
  display: 'grid',
  gridGap: '20px',
  marginBottom: '16px'
}))

const EmptyCartText = styled.p(({ theme }: any) => ({
  color: theme.colors.black,
  fontSize: theme.fontSizes[2],
  fontWeight: 500,
  marginBottom: '60px'
}))

const EmptyCartContainer = styled.div(({ theme }: any) => ({
  margin: '35% 15% 0',
  textAlign: 'center',
  maxWidth: '350px',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  alignContent: 'center',
  flexDirection: 'column'
}))

const CartIcon = styled(BsCart2)(() => ({
  width: '80px',
  height: '80px'
}))

export default CartModal
