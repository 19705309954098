import { Switch } from './FormElements'
import { inputCharactersLimitValidator } from 'shop/components/Checkout'
import { FieldError, UseFormMethods } from 'react-hook-form'
import FormInput from '../Inputs/FormInput'
import { FormFields } from './types'
import { HeaderWithSwitch } from './commonStyles'

type OrderNotesProps = {
  formHandle: UseFormMethods<FormFields>
  placeholder: string
  enabled: boolean
  error?: FieldError
}

const OrderNotes = ({
  error,
  placeholder,
  formHandle,
  enabled
}: OrderNotesProps) => {
  const { register, watch } = formHandle
  const orderNotes = watch('orderNotes')

  return (
    <>
      <HeaderWithSwitch enabled={!!enabled}>
        <Switch
          formRef={register}
          name={'orderNotesEnabled'}
          labelText='Add order notes'
        />
      </HeaderWithSwitch>
      {enabled && (
        <FormInput
          id={'orderNotes'}
          variant='textarea'
          value={orderNotes}
          data-testid='order-notes-text-area'
          labelText='Order notes'
          formRef={register({
            required: 'Please enter order notes',
            validate: inputCharactersLimitValidator('Order notes')
          })}
          name='orderNotes'
          placeholder={placeholder}
          showError={!!error}
        />
      )}
    </>
  )
}

export default OrderNotes
